import { AudioOfSequence } from './audio-of-sequence';
/**
 * Entity class for simple playlist
 */
export class AnnouncementPlaylist {
  /**
   * playlist's id
   */
  id: Number;
  /**
   * playlist's name
   */
  name: string;
  /**
   * sequence
   */
  sequence: string;
  /**
   * list media of sequence
   */
  mediaOfSequences: Array<AudioOfSequence>;

  /**
   * true if edit playlist
   */
  isEdit: boolean;

  /**
   * playlist type
   */
  playlistType: Number;

  /**
   * isChecking
   */
  isChecking: boolean;

  constructor(id?: Number, name?: string, playlistType?: Number) {
    this.id = id;
    this.name = name;
    this.playlistType = playlistType;
  }
}
