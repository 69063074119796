import { PublishData } from './publish-data';

/**
 * class DataLog
 */
export class DataLog {
  name: string;
  current: PublishData;
  next: PublishData;
}
