export class UpperLimit {
  /**
   * current
   */
  current: number;
  /**
   * number
   */
  item: number;

  constructor(current?: number, item?: number) {
    this.current = current;
    this.item = item;
  }
}
