import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionState } from 'app/config/action-state';
import { FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'menu-digital-signage',
  templateUrl: './menu-digital-signage.component.html',
  styleUrls: ['./menu-digital-signage.component.scss']
})
export class MenuDigitalSignageComponent implements OnInit {
  isCheckWidthMenu: boolean;
  isCheckPublish: boolean;
  /**
   * action states for module 1
   */
  actionState1: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);
  isPublish: boolean;

  constructor(private menuActionService: MenuActionService, private readonly store: Store<AppState>, private dataService: DataService) {
    this.store
      .select(state => state)
      .subscribe(templateState => {
        this.actionState1.canUndo = templateState.templateEditorState.canUndo;
        this.actionState1.canRedo = templateState.templateEditorState.canRedo;
      });

    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'publish') {
        this.isPublish = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {
    this.handlingDisplayMenu();
  }

  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;

  /**
   * execute action for Save in menu
   * @param {string} moduleName current module name
   */
  save(moduleName: string) {
    this.menuActionService.save(moduleName);
  }

  /**
   * execute action for Add New Daily in menu
   * @param {string} moduleName current module name
   */
  addNewDaily(moduleName: string) {
    this.menuActionService.addNewDaily(moduleName);
  }

  /**
   * execute action for Add New Channel in menu
   * @param {string} moduleName current module name
   */
  addNewChannel(moduleName: string) {
    this.menuActionService.addNewChannel(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for Delete in menu
   * @param {string} moduleName current module name
   */
  deleteSignage(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for Undo in menu
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for Redo in menu
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }

  /**
   * execute action for Change Template in menu
   * @param {string} moduleName current module name
   */
  publish(moduleName: string) {
    this.menuActionService.publish(moduleName);
  }

  /**
   * handle  menu  when resize screen
   */
  handlingDisplayMenu() {
    this.isCheckWidthMenu = true;
    this.isCheckPublish = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'checkPublishDigital') {
        this.isCheckPublish = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckPublish;
    });
  }
}
