import { Area } from './area';
import { ExternalContentDetail } from './external-content-detail';
import { IndexWordGroup } from './index-word-group';

export class WeatherContentDetail extends ExternalContentDetail {
  forecastParam: number;
  indexWordGroup: IndexWordGroup;

  constructor(area?: Area, source?: number, externalContentId?: Number, forecastParam?: number, indexWordGroup?: IndexWordGroup) {
    super(area, source, externalContentId);
    this.forecastParam = forecastParam;
    this.indexWordGroup = indexWordGroup;
  }
}
