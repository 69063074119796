export class PlayTimingSetting {
  id: Number;
  playbackTime1: Number;
  playbackTime2: Number;
  isPlayDelay: boolean;
  isPlayArrive: boolean;
  start: any;
  end: any;
  playPeriod: Number;

  constructor(
    playbackTime1: Number,
    playbackTime2: Number,
    start: any,
    end: any,
    playPeriod: Number,
    isPlayDelay: boolean,
    isPlayArrive: boolean
  ) {
    this.playbackTime1 = playbackTime1;
    this.playbackTime2 = playbackTime2;
    this.start = start;
    this.end = end;
    this.playPeriod = playPeriod;
    this.isPlayDelay = isPlayDelay;
    this.isPlayArrive = isPlayArrive;
  }
}
