export class MessageErrorTabTranslation {
  /**
   * formatErrorFile
   */
  formatErrorFile: Array<String>;

  /**
   * specialCharError
   */
  isSpecialCharError: boolean;

  /**
   * maxLengthError
   */
  isMaxLengthError: boolean;

  /**
   * is duplicate
   */
  isDuplicate: boolean;

  /**
   * otherError
   */
  otherError: Array<String>;

  /**
   * isValid
   */

  isValid: boolean;

  constructor(
    formatErrorFile?: Array<String>,
    isSpecialCharError?: boolean,
    isMaxLengthError?: boolean,
    isDuplicate?: boolean,
    otherError?: Array<String>,
    isValid?: boolean
  ) {
    this.formatErrorFile = formatErrorFile;
    this.isSpecialCharError = isSpecialCharError;
    this.isMaxLengthError = isMaxLengthError;
    this.isDuplicate = isDuplicate;
    this.otherError = otherError;
    this.isValid = isValid;
  }
}
