import { Component, OnInit } from '@angular/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-dashboard',
  templateUrl: './menu-dashboard.component.html',
  styleUrls: ['./menu-dashboard.component.scss']
})
export class MenuDashboardComponent implements OnInit {
  /**
   * constants
   */
  private readonly DISABLE_LATEST_MEDIA_LOG = 'disableLatestMediaLog';

  /**
   * true if button get latest media log disable
   */
  isDisableLatestMedia: boolean;
  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;

  /**
   * disable button setting when choose tab Content Report
   */
  isDisable: boolean;

  constructor(private menuActionService: MenuActionService, private dataService: DataService) {
    this.dataService.currentData.subscribe(data => {
      if (data[0] == this.DISABLE_LATEST_MEDIA_LOG) {
        this.isDisableLatestMedia = <boolean>data[1];
      }
      if (data[0] == Constant.DISABLE_BUTTON_DASHBOARD) {
        this.isDisable = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {}

  /**
   * execute action for Get Latest Media Log in menu File
   * @param {string} moduleName current module name
   */
  getLatestMediaLog(moduleName: string) {
    this.menuActionService.getLatestMediaLog(moduleName);
  }

  /**
   * execute action for Export Media Log in menu File
   * @param {string} moduleName current module name
   */
  exportMediaLog(moduleName: string) {
    this.menuActionService.exportMediaLog(moduleName);
  }

  /**
   * execute action for Export Content Report in menu File
   * @param {string} moduleName current module name
   */
  exportContentReport(moduleName: string) {
    this.menuActionService.exportContentReport(moduleName);
  }

  /**
   * execute action for clear setting in menu Edit
   * @param {string} moduleName current module name
   */
  clearSetting(moduleName: string) {
    this.menuActionService.clearSetting(moduleName);
  }

  /**
   * execute action for menu setting
   * @param {string} moduleName current module name
   */
  setting(moduleName: string) {
    this.menuActionService.setting(moduleName);
  }

  /**
   * commandDevice
   * @param moduleName
   */
  commandDevice(moduleName: string) {
    this.menuActionService.commandDevice(moduleName);
  }
}
