export class City {
  id: number;
  name: string;
  latitude: string;
  longitude: string;

  constructor(id?: number) {
    this.id = id;
  }
}
