import { Component, OnInit, ViewChild, ElementRef, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionState } from 'app/config/action-state';
import { FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'menu-delivery',
  templateUrl: './menu-delivery.component.html',
  styleUrls: ['./menu-delivery.component.scss']
})
export class MenuDeliveryComponent implements OnInit {
  isCheckWidthMenu: boolean;
  isCheckDelivery: boolean;
  isCheckJob: boolean;
  isCheckSetting: boolean;
  isCheckEmergency: boolean;
  /**
   * action states for module 1
   */
  actionState1: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);

  constructor(private menuActionService: MenuActionService, private readonly store: Store<AppState>, private dataService: DataService) {
    this.store
      .select(state => state)
      .subscribe(templateState => {
        this.actionState1.canUndo = templateState.templateEditorState.canUndo;
        this.actionState1.canRedo = templateState.templateEditorState.canRedo;
      });
  }

  ngOnInit(): void {
    this.handlingDisplayMenu();
  }

  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;

  /**
   * toggle sort and filter item
   * @param {string} moduleName module name
   */
  toggleSortAndFilter(moduleName: string) {
    this.menuActionService.toggleSortAndFilter(moduleName);
  }

  /**
   * delete archive note
   * @param {string} moduleName module name
   */
  deleteArchive(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for Undo in menu
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for Redo in menu
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }

  /**
   * delivery
   * @param {string} moduleName module name
   */
  delivery(moduleName: string) {
    this.menuActionService.delivery(moduleName);
  }

  /**
   * emergency mod
   * @param {string} moduleName module name
   */
  emergency(moduleName: string) {
    this.menuActionService.emergency(moduleName);
  }

  /**
   * open delivery job
   */
  openDeliveryJob(moduleName: string) {
    this.menuActionService.openDeliveryJob(moduleName);
  }

  /**
   * open setting
   */
  openSetting(moduleName: string) {
    this.menuActionService.openSetting(moduleName);
  }

  /**
   * handle  menu  when resize screen
   */
  handlingDisplayMenu() {
    this.isCheckWidthMenu = true;
    this.isCheckDelivery = true;
    this.isCheckJob = true;
    this.isCheckSetting = true;
    this.isCheckEmergency = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'checkEmergency') {
        this.isCheckEmergency = <boolean>data[1];
      }
      if (data[0] == 'checkSetting') {
        this.isCheckSetting = <boolean>data[1];
      }
      if (data[0] == 'checkJob') {
        this.isCheckJob = <boolean>data[1];
      }
      if (data[0] == 'checkDelivery') {
        this.isCheckDelivery = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckDelivery && this.isCheckJob && this.isCheckSetting && this.isCheckEmergency;
    });
  }
}
