import { ContentDay } from './content-day';

/**
 * class entity for device log
 */
export class DeviceCalendar {
  id: Number;
  name: string;
  registrationId: string;
  contentDays: Array<ContentDay>;
  calendarDays: Array<ContentDay>;
  startDate: Date;
  isChecked: boolean;
  status: string;
  timeDateLine: number;
  detailStatusCode: string;
  detailStatusDisplay: string;
  jobId: string;
  groupId: string;
  isDelivering: boolean;
}
