import { Privilege } from './privilege';
import { UserSetting } from './user-setting';
/**
 * Entity class common
 */
export class Common {
  // common
  userId: any;
  userName: string;
  userIdString: string;
  tenantName: string;
  setting: UserSetting;
  fieldComponent1: string;
  fieldComponent2: string;
  fieldComponent3: string;
  customerApiUrl: string;
  layout: number;
  // user manager
  privileges: Privilege[];
  user: any;
  // project
  projectId: Number;
  projectName: string;
  // on-bus
  isDisPlay2OnBus: boolean;
  // station
  isDisplay2Station: boolean;
  // signage
  isDisplay2Signage: boolean;
  // timetable
  isDisplay2Timetable: boolean;
  // timetable operation
  isDisplay2TimetableOperation: boolean;
  // true if logout
  isLogout: boolean;
  // true if mode monitor is viewing
  isViewMonitorMode: boolean;
  // index of device in timetable operation
  timetableOperationDeviceId: number;
  // tab status in User Manager
  isShowUserList: boolean;
  // is active tab preview in Media Manager
  isActiveTabPreview: boolean;
  // index word group selected id in Index Word Group
  indexWordGroupSelectedId: number;
  // true if choose tab schedule in Timetable Editor
  isSchedule: boolean;
  // true if channel tab is showed and vice versa in Digital Signage Channel Editor
  isShowChannel: boolean;
  // true if news tab is active in External Content Manager
  isActiveNewsTab: boolean;
  //true if weather tab is active in External Content Manager
  isActiveWeatherTab: boolean;
  // true if active tab playlist in Simple Signage Display
  isTabPlaylist: boolean;
  // True if active tab device monitor in Dashboard
  isActiveDeviceMonitorTab: boolean;
  // True if active tab properties monitor in Dashboard
  isActivePropertiesTab: boolean;
  // True if active tab log in device monitor Dashboard
  isActiveLogTab: boolean;
  // Selected index word group id in Master List Editor
  selectedIndexWordGroupId: number;
  // Selected page in Master List Editor
  pageSelected: number;
  // fonts
  fonts: any[];
  // true if checked expiration in Simple Signage Editor
  isCheckedExpiration: boolean;
  // true if checked playback time in Simple Signage Editor
  isCheckedPlaybackTime: boolean;
  // true if all fonts loaded
  isLoadedFonts: boolean;
  // module name
  moduleName: string;
  /**
   * true if active tab create playlist
   */
  isTabCreatePlaylist: boolean;
  /**
   * true if active tab common playlist
   */
  isTabCommonPlaylist: boolean;
  /**
   * true if active tab register playlist
   */
  isTabRegisterPlaylist: boolean;

  constructor(isLogout?: boolean, setting?: UserSetting) {
    this.isLogout = isLogout;
    this.setting = setting;
  }
}
