import { ActiveColumnHeader, StatusNumberObject } from 'app/config/constants';
import { DeviceCommonSpecific } from './device-common-specific';

export class GroupDeviceAnnouncement {
  name: string;

  deviceCommonSpecifics: Array<DeviceCommonSpecific>;

  isExpand: boolean;

  isChecking: boolean;

  statusNumberObject: StatusNumberObject;

  statusNumberObjectOld: StatusNumberObject;

  groupJobId: string;

  groupId: string;

  activeColumnHeader: ActiveColumnHeader;
}
