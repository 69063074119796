export class BusStopRoute {
  index: Number;
  no: string;
  name: string;
  adjustedName: string;
  isChecked: boolean;
  isEdited: boolean;

  constructor(index?: Number, no?: string, name?: string, adjustedName?: string) {
    this.index = index;
    this.no = no;
    this.name = name;
    this.adjustedName = adjustedName;
  }
}
