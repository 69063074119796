export class CustomTagElement {
  id: number;
  name: string;
  customTagId: Number;
  groupId: string;

  constructor(name: string, customTagId: Number, id?: number, groupId?: string) {
    this.name = name;
    this.customTagId = customTagId;
    this.id = id;
    this.groupId = groupId;
  }
}
