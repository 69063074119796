import { Device } from './device';
import { Media } from './media';
import { PictureArea } from './picture-area';
import { TextArea } from './text-area';

export class DynamicMessage {
  /**
   * id
   */
  id: Number;
  /**
   * textArea
   */
  textArea: TextArea;
  /**
   * message
   */
  message: string;
  /**
   * device
   */
  device: Device;
  /**
   * true if editing
   */
  isEdit: boolean = false;
  /**
   * isChanged
   */
  isChanged: boolean = false;
  /**
   * pictureArea
   */
  pictureArea: PictureArea;
  /**
   * uuidV4 image
   */
  uuidV4Image: any;
  /**
   * media
   */
  media: Media;
  /**
   * media from PC
   */
  mediaFromPC: Media;

  constructor(textArea?: TextArea, message?: string, device?: Device) {
    this.textArea = textArea;
    this.message = message;
    this.device = device;
  }
}
