import { Component, OnInit } from '@angular/core';
import { FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-timetable-operation-manager',
  templateUrl: './menu-timetable-operation-manager.component.html',
  styleUrls: ['./menu-timetable-operation-manager.component.scss']
})
export class MenuTimetableOperationManagerComponent implements OnInit {
  isCheckWidthMenu: boolean;
  isCheckEmergency: boolean;
  isCheckMessage: boolean;
  isCheckDisplay: boolean;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  isDynamicMessageSelected: boolean;
  public isEditDynamicMessageMode: boolean = false;
  public isEditEmergencyMode: boolean = false;
  public isChecked: boolean = false;
  public isPlayPreview: boolean = false;
  public isViewMonitorMode: boolean = false;
  isDisabled: boolean;
  isDisabledEdit: boolean;
  constructor(private menuActionService: MenuActionService, private dataService: DataService, private commonService: CommonService) {
    this.isChecked = commonService.getCommonObject().isDisplay2TimetableOperation;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isEditDynamicMessageMode`) {
        this.isEditDynamicMessageMode = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isEditEmergencyMode`) {
        this.isEditEmergencyMode = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isPlayPreview`) {
        this.isPlayPreview = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:isSelectDynamicMessage`) {
        this.isDynamicMessageSelected = <boolean>data[1];
      }
      this.isDisabled = this.isEditEmergencyMode || this.isEditDynamicMessageMode || this.isPlayPreview;
      this.isDisabledEdit = this.isEditEmergencyMode || this.isPlayPreview;
    });
  }

  ngOnInit(): void {
    this.handlingDisplayMenu();
  }

  /**
   * open dynamic message
   * @param moduleName
   */
  openDynamicMessage(moduleName: string) {
    this.menuActionService.openDynamicMessage(moduleName);
  }

  /**
   * open emergency
   *
   * @param moduleName
   */
  openEmergency(moduleName: string) {
    this.menuActionService.emergency(moduleName);
  }

  /**
   * stop propagation
   * @param e MouseEvent
   */
  displayTemplate(e: MouseEvent) {
    e.stopPropagation();
  }

  /**
   * execute action for Change Display in menu
   *
   * @param {string} moduleName current module name
   */
  changeDisplay(moduleName: string) {
    this.isChecked = !this.isChecked;
    this.menuActionService.changeDisplay(moduleName);
  }

  /**
   * execute action for Operation Setting in menu
   *
   * @param {string} moduleName current module name
   */
  operationSetting(moduleName: string) {
    this.menuActionService.operationSetting(moduleName);
  }

  /**
   * execute action for clear field free area in menu
   * @param {string} moduleName current module name
   */
  clearField(moduleName: string) {
    this.menuActionService.clearField(moduleName);
  }

  /**
   * handle  menu  when resize screen
   */
  handlingDisplayMenu() {
    this.isCheckWidthMenu = true;
    this.isCheckEmergency = true;
    this.isCheckMessage = true;
    this.isCheckDisplay = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:checkDisplay`) {
        this.isCheckDisplay = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:checkEmergency`) {
        this.isCheckEmergency = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:checkMessage`) {
        this.isCheckMessage = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckEmergency && this.isCheckDisplay && this.isCheckMessage;
    });
  }
}
