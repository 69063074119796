import { ObjectFitEnum } from '../../config/constants';
import { FolderMedia } from './folder-media';
import { Media } from './media';

/**
 * Entity class for sequence
 */
export class Sequence extends Media {
  /**
   * name
   */
  name: string;
  /**
   * width
   */
  width: number;
  /**
   * height
   */
  height: number;
  /**
   * frame rate (frame/s)
   */
  frameRate: number;
  /**
   * object fit
   */
  objectFit: ObjectFitEnum;
  /**
   * list media of sequence
   */
  medias: Array<Media>;
  /**
   * total duration
   */
  totalDuration: string;
  /**
   * folder S3
   */
  folderS3: FolderMedia;
  /**
   * folderId
   */
  folderId: Number;
  constructor(name?: string, width?: number, height?: number, folderS3?: FolderMedia, medias?: Array<Media>) {
    super();
    this.name = name;
    this.width = width;
    this.height = height;
    this.objectFit = ObjectFitEnum.FILL;
    this.folderS3 = folderS3;
    this.medias = medias;
  }

  getMedia() {
    return this;
  }
}
