import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionState } from 'app/config/action-state';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'menu-lcd',
  templateUrl: './menu-lcd.component.html',
  styleUrls: ['./menu-lcd.component.scss']
})
export class MenuLcdComponent implements OnInit {
  /**
   * action states for module 1
   */
  actionState: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);
  /**
   * true if show list template group
   */
  isShowFolder: boolean = true;
  /**
   * true if select folder
   */
  isSelectFolder: boolean = false;
  /**
   * true if show template detail (canvas)
   */
  isShowCanvas: boolean = false;
  /**
   * true if template detail has template
   */
  hasTemplate: boolean;
  /**
   * isSelectTemplate
   */
  isSelectTemplate: boolean;
  /**
   * can sort filter
   */
  public canSortFilter: boolean;
  /**
   * true if show Menu Setting
   */
  public isShowMenuSetting: boolean;

  isUserRoot: boolean;
  constructor(private menuActionService: MenuActionService, private readonly store: Store<AppState>, private dataService: DataService) {
    this.store
      .select(state => state)
      .subscribe(templateState => {
        this.actionState.canUndo = templateState.templateEditorState.canUndo;
        this.actionState.canRedo = templateState.templateEditorState.canRedo;
      });
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'isShowFolder') {
        this.isShowFolder = <boolean>data[1];
      }
      if (data[0] == 'isSelectFolder') {
        this.isSelectFolder = <boolean>data[1];
      }
      if (data[0] == 'isShowCanvas') {
        this.isShowCanvas = <boolean>data[1];
      }
      if (data[0] == 'hasTemplate') {
        this.hasTemplate = <boolean>data[1];
      }
      if (data[0] == Constant.CAN_SORT_FILTER) {
        this.canSortFilter = <boolean>data[1];
      }
      if (data[0] == Constant.IS_SHOW_MENU_SETTING) {
        this.isShowMenuSetting = <boolean>data[1];
      }
      if (data[0] == 'isUserRoot') {
        this.isUserRoot = <boolean>data[1];
      }
      if (data[0] == 'isSelectTemplate') {
        this.isSelectTemplate = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {}

  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;

  /**
   * execute action for Save in menu
   * @param {string} moduleName current module name
   */
  save(moduleName: string) {
    this.menuActionService.save(moduleName);
  }

  /**
   * execute action for Save as in menu
   * @param moduleName current module name
   */
  saveAs(moduleName: string) {
    this.menuActionService.saveAs(moduleName);
  }

  /**
   * execute action for Add New Folder in menu
   * @param {string} moduleName current module name
   */
  addFolder(moduleName: string) {
    this.menuActionService.addFolder(moduleName);
  }

  /**
   * execute action for Add in menu
   * @param {string} moduleName current module name
   */
  add(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for Exit in menu
   * @param {string} moduleName current module name
   */
  exit(moduleName: string) {
    this.menuActionService.exit(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for Duplicate in menu
   * @param {string} moduleName current module name
   */
  duplicate(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }

  /**
   * execute action for sort and filter in menu
   * @param {string} moduleName current module name
   */
  toggleSortAndFilter(moduleName: string): void {
    this.menuActionService.toggleSortAndFilter(moduleName);
  }

  /**
   * execute action for Delete in menu
   * @param {string} moduleName current module name
   */
  deleteTemplate(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for Undo in menu
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for Redo in menu
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }

  /**
   * execute action for import template in menu
   * @param {string} moduleName current module name
   */
  importTemplate(moduleName: string) {
    this.menuActionService.importTemplate(moduleName);
  }

  /**
   * execute action for export template in menu
   * @param {string} moduleName current module name
   */
  exportTemplate(moduleName: string) {
    this.menuActionService.exportTemplate(moduleName);
  }

  /**
   * execute action for setting upper limit in menu
   * @param {string} moduleName current module name
   */
  public settingUpperLimit(moduleName: string) {
    this.menuActionService.settingUpperLimit(moduleName);
  }
}
