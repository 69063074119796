/**
 * entity class for a ChangePass
 */
export class ChangePassword {
  currentPassword: string;
  newPassword: string;
  constructor(currentPassword?: string, newPassword?: string) {
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
  }
}
