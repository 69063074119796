/**
 * Entity class for simple media
 */
export class SimpleMedia {
  /**
   * media's id
   */
  id: Number;
  /**
   * media's name
   */
  name: string;
  /**
   * media's type
   */
  type: string;
  /**
   * media's url
   */
  url: string;
  /**
   * start time
   */
  startTime: string;
  /**
   * folder id
   */
  folderId: Number;
  /**
   * valid from date
   */
  validFrom: string;
  /**
   * valid to date
   */
  validTo: string;
  /**
   * duration
   */
  duration: string;
  /**
   * width
   */
  width: number;
  /**
   * height
   */
  height: number;
  /**
   * random number
   */
  randomNumber: number;
  /**
   * media name encode
   */
  mediaNameEncode: string;
  /**
   * size
   */
  size: number;
  /**
   * frame rate
   */
  frameRate: string;
  /**
   * bit rate
   */
  bitRate: string;

  /**
   * isOriginalContent
   */
  isOriginalContent: boolean;

  constructor(name?: string, type?: string) {
    this.name = name;
    this.type = type;
  }
}
