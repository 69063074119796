export class TicketInformation {
  id: number;

  /**
   * ticketId
   */
  ticketId: number;

  /**
   * ticketId
   */
  ticketName: any;

  /**
   * note
   */
  note: any;
  /**
   * id Spot
   */
  idSpot: string;

  /**
   * isShowTicketName
   */
  isShowTicketName: boolean;

  /**
   * isShowNote
   */
  isShowNote: boolean;

  languageSelected: string;
  constructor(id?: number, ticketId?: number, ticketName?: any, note?: any, isShowTicketName: boolean = true, isShowNote: boolean = true) {
    this.id = id;
    this.ticketId = ticketId;
    this.ticketName = ticketName;
    this.note = note;
    this.isShowTicketName = isShowTicketName;
    this.isShowNote = isShowNote;
  }
}
