import { Area } from './area';
import { v4 as uuidv4 } from 'uuid';

/**
 * Entity class for layer
 */
export class Layer {
  /**
   * layer id
   */
  id: Number;
  /**
   * index's layer
   */
  index: number;
  /**
   * layer's name
   */
  name: string;
  /**
   * layer's templateid
   */
  templateId: Number;
  /**
   * area list's layer
   */
  areas: Array<Area>;
  /**
   * show area list
   * default: false
   * true if user click to show area list
   */
  isShowArea: boolean;
  /**
   * default: false
   * true if user click to hidden this layer
   */
  isHidden: boolean;
  /**
   * default: false
   * true if user click to lock this layer
   */
  isLock: boolean;
  /**
   * default: false
   * true if user double click to edit name layer
   */
  isEditName: boolean;
  /**
   *
   */
  elementId: string;
  /**
   *
   */
  symbol: any;

  /**
   * default: false
   * true if user set switch area to on
   */
  isSwitchingArea: boolean;

  constructor(name?: string, index?: number, templateId?: Number, areas?: Array<Area>) {
    this.name = name;
    this.index = index;
    this.areas = areas;
    this.templateId = templateId;
    this.isShowArea = false;
    this.isHidden = false;
    this.isLock = false;
    this.isEditName = false;
    this.elementId = 'layer-' + uuidv4();
    this.isSwitchingArea = false;
    if (!this.symbol) {
      this.symbol = Symbol();
    }
  }
}
