import {
  AlignmentEnum,
  Constant,
  FontTypeEnum,
  LinkDataTextLEDEnum,
  ObjectFitEnum,
  OrientationEnum,
  ReferencePositionColumnEDSEnum
} from 'app/config/constants';
import { AreaLED } from './area-led';

/**
 * Entity class for text area led
 */
export class TextAreaLED extends AreaLED {
  /**
   * text's text area
   */
  text: string;
  /**
   * font size's text
   */
  fontSize: number;
  /**
   * font name's text
   */
  fontName: string;
  /**
   * outline size's text
   */
  outlineSize: number;
  /**
   * align horizontal: left, center, right
   * default: left
   */
  horizontalTextAlignment: AlignmentEnum;
  /**
   * align vertical: top, middle, bottom
   * default: top
   */
  verticalTextAlignment: AlignmentEnum;
  /**
   * text orientation: horizontal, vertical, sideways
   * default: horizontal
   */
  orientation: OrientationEnum;
  /**
   * link reference data:
   * default: route no.
   */
  linkReferenceData: LinkDataTextLEDEnum;
  /**
   * font color
   */
  fontColor: string;
  /**
   * background color
   */
  backgroundColor: string;
  /**
   * outline color
   */
  outlineColor: string;
  /**
   * area link
   */
  areaLink: boolean;
  /**
   * link with
   */
  linkWith: string;
  /**
   * lead spacing
   */
  leadSpacing: number;
  /**
   * letter spacing
   */
  letterSpacing: number;
  /**
   * baseline height
   */
  baselineHeight: number;
  /**
   * index word group id
   */
  indexWordGroupId: number;
  /**
   * reference position column
   */
  referencePositionColumn: ReferencePositionColumnEDSEnum;
  /**
   * changeover
   */
  changeover: boolean;
  /**
   * duration
   */
  duration: number;
  /**
   * delimiters
   */
  delimiters: number;
  /**
   * other delimiters
   */
  otherDelimiters: string;
  /**
   * object fit
   */
  objectFit: ObjectFitEnum;
  /**
   * font
   */
  font: any;
  /**
   * font type
   */
  fontType: FontTypeEnum;
  /**
   * font sizes bitmap
   */
  fontSizesBitmap: Array<string>;
  /**
   * true if hide changeover setting
   */
  isHideChangeOver: boolean;
  /**
   * areasLinkWith
   */
  areasLinkWith: Array<AreaLED>;

  constructor(
    name?: string,
    width?: number,
    height?: number,
    posX?: number,
    posY?: number,
    index?: number,
    isFix?: boolean,
    text?: string
  ) {
    super(name, width, height, posX, posY, index, isFix);
    this.text = text;
    this.horizontalTextAlignment = AlignmentEnum.LEFT;
    this.verticalTextAlignment = AlignmentEnum.TOP;
    this.orientation = OrientationEnum.HORIZONTAL;
    if (!isFix) {
      this.linkReferenceData = LinkDataTextLEDEnum.ROUTE_NO;
    }
    this.leadSpacing = 0;
    this.letterSpacing = 0;
    this.baselineHeight = 0;
    this.areaLink = false;
    this.changeover = true;
    this.duration = 5;
  }

  /**
   * override from area class
   * check type area
   * return true if area is text area
   */
  checkTypeTextArea(): boolean {
    return true;
  }

  /**
   * override from area class
   * get text area
   */
  getArea() {
    return this;
  }
}
