import { PlaylistType } from 'app/config/constants';

export class PlayListTranslation {
  /**
   * playListTranslation id
   */
  id: Number;

  /**
  /**
   * fileName
   */
  fileName: string;

  /**
  /**
   * isCreatePlaylist
   */
  isCreatePlaylist: boolean;

  /**
  /**
   * playlistType
   */
  playlistType: Number;

  /**
  /**
   * playlistName
   */
  playlistName: string;

  /**
  /**
   * listSpeechSynthesis
   */
  speechSynthesis: Array<SpeechSynthesis>;

  constructor(
    id?: Number,
    fileName?: string,
    isCreatePlaylist?: boolean,
    playlistType?: PlaylistType,
    playlistName?: string,
    speechSynthesis?: Array<any>
  ) {
    this.id = id;
    this.fileName = fileName;
    this.isCreatePlaylist = isCreatePlaylist;
    this.playlistType = playlistType;
    this.playlistName = playlistName;
    this.speechSynthesis = speechSynthesis;
  }
}

export interface SpeechSynthesis {
  languageName: string;
  pollyLanguageCode: string;
}
