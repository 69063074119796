import { CustomTagElement } from './custom-tag-element';

/**
 * entity class for custom tag
 */
export class CustomTag{
    /**
     * custom tag id
     */
    id: Number;
    /**
     * custom tag name;
     */
    name: string;
    /**
     * list element
     */
    elements: Array<CustomTagElement>;
    /**
     * number of custom tag
     */
    indexCustom: number;
    /**
     * contructor method
     * @param name custom tag name
     */
    constructor(name: string, indexCustom: number, elements?: Array<CustomTagElement>) {
        this.name = name;
        this.elements = elements;
        this.indexCustom = indexCustom;
    }
}