import { LinkDataPictureLEDEnum, ObjectFitEnum, ReferencePositionColumnEDSEnum } from 'app/config/constants';
import { Media } from '../media';
import { AreaLED } from './area-led';

export class PictureAreaLED extends AreaLED {
  /**
   *
   */
  media: Media;
  /**
   *
   */
  objectFit: ObjectFitEnum;

  /**
   * link reference data:
   * default: route no.
   */
  linkReferenceData: LinkDataPictureLEDEnum;
  indexWordGroupId: number;
  referencePositionColumn: ReferencePositionColumnEDSEnum;
  /**
   * true if flipbook on
   */
  isFlipbook: boolean;

  constructor(name?: string, width?: number, height?: number, posX?: number, posY?: number, index?: number, isFix?: boolean) {
    super(name, width, height, posX, posY, index, isFix);
    this.objectFit = ObjectFitEnum.FILL;
    if (!isFix) {
      this.linkReferenceData = LinkDataPictureLEDEnum.INDEX_WORD;
      this.referencePositionColumn = ReferencePositionColumnEDSEnum.ROUTE_NO;
    }
  }

  /**
   * override from area class
   * check type area
   * return false if area is picture area
   */
  checkTypeTextArea(): boolean {
    return false;
  }

  /**
   * override from area class
   * get picture area
   */
  getArea() {
    return this;
  }
}
