import { BusStop } from './bus-stop';
import { Media } from './media';
import { Route } from './route';

export class StationCell {
  /**
   * no
   */
  no: number;
  /**
   * media
   */
  media: Media;
  /**
   * id bus stop
   */
  busStopId: Number;
  /**
   * route
   */
  route: Route;
  /**
   * bus stop
   */
  busStop: BusStop;

  constructor(no?: number, media?: Media, busStopId?: Number, route?: Route, busStop?: BusStop) {
    this.no = no;
    this.media = media;
    this.busStopId = busStopId;
    this.route = route;
    this.busStop = busStop;
  }
}
