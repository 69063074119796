/**
 * Entity class for folder
 */
export class ScheduleOperationFolder {
  /**
   * folder's id
   */
  id: Number;
  /**
   * folder's name
   */
  name: string;
  /**
   * folderS3Name
   */
  folderS3Name: string;
  /**
   * type
   */
  type: number;
  /**
   * true if edit folder
   */
  isEdit: boolean;

  constructor(id?: Number, name?: string, type?: number) {
    this.id = id;
    this.name = name;
    this.type = type;
  }
}
