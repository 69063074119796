/**
 * Entity class for label
 */
export class Label {
    /**
     * label id
     */
    id: Number;
    /**
     * label name
     */
    name: string;
    /**
   * true if label is edited, and vice versa
   */
    isEdit: boolean;

    constructor(name?: string) {
        this.name = name;
    }
}