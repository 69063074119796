/**
 * Entity class for index word group
 */
export class IndexWordGroup {
  /**
   * index word group's ID
   */
  id: number;
  /**
   * index word group's name
   */
  name: string;
  constructor(name: string) {
    this.name = name;
  }
}
