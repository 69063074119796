export class APIDetails {
  id: number;
  apiKey: string;
  url: string;

  constructor(apiKey?: string, url?: string) {
    this.apiKey = apiKey;
    this.url = url;
  }
}
