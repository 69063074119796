/**
 * Entity class for simple media
 */
export class AnnouncementMedia {
  /**
   * media's id
   */
  id: Number;
  /**
   * media's name
   */
  name: string;
  /**
   * media's type
   */
  type: string;
  /**
   * media's url
   */
  url: string;
  /**
   * start time
   */
  startTime: string;
  /**
   * folder id
   */
  folderId: Number;
  /**
   * duration
   */
  duration: string;
  /**
   * random number
   */
  randomNumber: number;
  /**
   * media name encode
   */
  mediaNameEncode: string;

  /**
   * size
   */
  size: number;

  /**
   * bit rate
   */
  bitRate: string;

  constructor(name?: string, type?: string) {
    this.name = name;
    this.type = type;
  }
}
