import { MediaOfSequence } from './media-of-sequence';

/**
 * Entity class for simple playlist
 */
export class SimplePlaylist {
  /**
   * playlist's id
   */
  id: Number;
  /**
   * playlist's name
   */
  name: string;
  /**
   * sequence
   */
  sequence: string;
  /**
   * list media of sequence
   */
  mediaOfSequences: Array<MediaOfSequence>;
  /**
   * true if edit playlist
   */
  isEdit: boolean;

  constructor(id?: Number, name?: string) {
    this.id = id;
    this.name = name;
  }
}
