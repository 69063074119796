export class PayloadDelivery {
  devicesId: Array<Number>;
  responsesPublish: Array<ResponsePublish>;

  constructor(devicesId?: Array<Number>, responsesPublish?: Array<ResponsePublish>) {
    this.devicesId = devicesId;
    this.responsesPublish = responsesPublish;
  }
}

export class ResponsePublish {
  deviceId: Number;
  publishFileName: string;
  groupId: string;

  constructor(deviceId?: Number, publishFileName?: string) {
    this.deviceId = deviceId;
    this.publishFileName = publishFileName;
  }
}
