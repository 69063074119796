/**
 * Entity class for setting publish
 */
export class PublishSetting {
  /**
   * id channel
   */
  idChannel: Number;
  /**
   * routesId
   */
  routesId: Array<Number>;
  /**
   * busStopsId
   */
  busStopsId: Array<Number>;
  /**
   * stylesId
   */
  stylesId: Array<Number>;
  /**
   * publisher
   */
  publisher: string;
  /**
   * becomes effective
   */
  validFrom: string;
  /**
   * end effective
   */
  validTo: string;
  /**
   * description
   */
  description: string;
  /**
   * project name
   */
  projectName: string;
  /**
   * project id
   */
  projectId: Number;
  /**
   * devicesId
   */
  devicesId: Array<Number>;
  /**
   * timezone
   */
  timezone: string;
  /**
   * eds routes
   */
  edsRoutes: string;
  /**
   * device Id
   */
  deviceId: Number;
  /**
   * offset value to delay the change over timing of timetable.
   */
  changeoverOffset: number;
  /**
   * switching area time
   */
  switchingAreaTime: number;
  /**
   * registrationId
   */
  registrationId: string;
  /**
   * registrationIds of device same data
   */
  registrationIdsOfOtherDevice: Array<string>;

  constructor(publisher?: string, projectName?: string, projectId?: Number, changeoverOffset?: number) {
    this.publisher = publisher;
    this.projectName = projectName;
    this.projectId = projectId;
    this.changeoverOffset = changeoverOffset;
  }
}
