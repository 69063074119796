import { Stock } from './stock';

export class ContentDayReservation {
  id: Number;
  day: number;
  fullDate: Date;
  isOtherMonth: boolean;
  inactive: boolean;
  isActive: boolean;
  isReserve: boolean;
  maxReservationCount: number;
  dynamicPriceType: string;
  isNoDataReserved?: boolean;
  stock: Stock[];

  constructor(day?: number, fullDate?: Date, inactive?: boolean, isActive?: boolean, isOtherMonth?: boolean) {
    this.day = day;
    this.fullDate = fullDate;
    this.inactive = inactive;
    this.isActive = isActive;
    this.isOtherMonth = isOtherMonth;
    this.isReserve = false;
  }

  public get dateInMonth(): string {
    return `${this.fullDate.getDate()}`;
  }
}
