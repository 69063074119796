import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionState } from 'app/config/action-state';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'menu-media',
  templateUrl: './menu-media.component.html',
  styleUrls: ['./menu-media.component.scss']
})
export class MenuMediaComponent implements OnInit {
  /**
   * action states for module 1
   */
  actionState1: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);
  /**
   * true if open station content folder
   */
  isOpenStationContentFolder: boolean = false;
  /**
   * true if disable menu
   */
  disableMenu: boolean;
  /**
   * true if disable menu delete
   */
  disableMenuDelete: boolean;
  readonly IS_OPEN_STATION_CONTENT_FOLDER = 'isOpenStationContentFolder';
  readonly IS_DISABLE = 'disableMenu';
  readonly IS_DISABLE_MENU_DELETE = 'disableMenuDelete';
  constructor(private menuActionService: MenuActionService, private readonly store: Store<AppState>, private dataService: DataService) {
    this.store
      .select(state => state)
      .subscribe(templateState => {
        this.actionState1.canUndo = templateState.templateEditorState.canUndo;
        this.actionState1.canRedo = templateState.templateEditorState.canRedo;
      });

    this.dataService.currentData.subscribe(data => {
      if (data[0] == this.IS_OPEN_STATION_CONTENT_FOLDER) {
        this.isOpenStationContentFolder = <boolean>data[1];
      }
      if (data[0] == this.IS_DISABLE || data[0] == Constant.IS_PLAY_MEDIA) {
        this.disableMenu = <boolean>data[1];
      }
      if (data[0] == this.IS_DISABLE_MENU_DELETE) {
        this.disableMenuDelete = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {}

  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;

  /**
   * execute action for import media in menu
   * @param {string} moduleName current module name
   */
  importMedia(moduleName: string) {
    this.menuActionService.importMedia(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for Duplicate in menu
   * @param {string} moduleName current module name
   */
  duplicate(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }

  /**
   * execute action for Delete in menu
   * @param {string} moduleName current module name
   */
  deleteMedia(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for add new sequence in menu
   * @param {string} moduleName current module name
   */
  addSequence(moduleName: string) {
    this.menuActionService.addSequence(moduleName);
  }

  /**
   * execute action for edit sequence in menu
   * @param {string} moduleName current module name
   */
  editSequence(moduleName: string) {
    this.menuActionService.editSequence(moduleName);
  }

  /**
   * execute action for Undo in menu
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for Redo in menu
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }
}
