import { Component, OnInit } from '@angular/core';
import { FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-station-display-editor',
  templateUrl: './menu-station-display-editor.component.html',
  styleUrls: ['./menu-station-display-editor.component.scss']
})
export class MenuStationDisplayEditorComponent implements OnInit {
  isCheckWidthMenu: boolean;
  isCheckDisplay: boolean;
  isCheckSetting: boolean;
  isCheckPublish: boolean;
  constructor(private menuActionService: MenuActionService, private dataService: DataService, private commonService: CommonService) {
    this.isChecked = commonService.getCommonObject().isDisplay2Station;
  }

  ngOnInit(): void {
    this.handlingDisplayMenu();
  }

  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;

  /**
   * true if choose display 2
   */
  isChecked: boolean = false;

  /**
   * execute action for save in menu
   * @param {string} moduleName current module name
   */
  save(moduleName: string) {
    this.menuActionService.save(moduleName);
  }

  /**
   * execute action for save in menu
   * @param {string} moduleName current module name
   */
  deleteMedia(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for change Template in menu
   * @param {string} moduleName current module name
   */
  changeTemplate(moduleName: string) {
    this.menuActionService.changeTemplate(moduleName);
  }

  /**
   * toggle sort and filter item
   * @param {string} moduleName module name
   */
  toggleSortAndFilter(moduleName: string) {
    this.menuActionService.toggleSortAndFilter(moduleName);
  }

  /**
   * stop propagation
   * @param e MouseEvent
   */
  displayTemplate(e: MouseEvent) {
    e.stopPropagation();
  }

  /**
   * execute action for Change Display in menu
   * @param {string} moduleName current module name
   */
  changeDisplay(moduleName: string) {
    this.isChecked = !this.isChecked;
    this.menuActionService.changeDisplay(moduleName);
  }

  /**
   * execute action for publish in menu
   * @param {string} moduleName current module name
   */
  publish(moduleName: string) {
    this.menuActionService.publish(moduleName);
  }

  /**
   * handle  menu  when resize screen
   */
  handlingDisplayMenu() {
    this.isCheckWidthMenu = true;
    this.isCheckDisplay = true;
    this.isCheckPublish = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'checkPublishStation') {
        this.isCheckPublish = <boolean>data[1];
      }
      if (data[0] == 'checkDisplayStation') {
        this.isCheckDisplay = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckDisplay && this.isCheckPublish;
    });
  }
}
