import { AnnouncementPlaylist } from './announcement-playlist';

export class DeviceCommonSpecific {
  id: number;
  name: string;
  commonAnnouncement1: number;
  commonAnnouncement2: number;
  periodicAnnouncement: number;
  registrationId: string;
  isChecking: boolean;
  status: string;
  detailStatusCode: string;
  detailStatusDisplay: string;
  jobId: string;
  groupId: string;
  isDelivering: boolean;
  nameGroup: string;
  playlistSpecific: Array<AnnouncementPlaylist>;
}
