export class StockDetail {
  stockPartitionId: number;
  ticketId: number;
  ticketName: any;
  tripId: string;
  name: any;
  reservationClassId: string;
  reservationClassName: any;
  stockPartitionDescription: any;
  stockId: number;
  reserveOn: string;
  is29hNotation: false;
  reservationPriceId: number;
  dynamicPriceType: string;
  reservationPriceDescription: any;
  currentReservationCount: 100;
  maxReservationCount: 999;
}
