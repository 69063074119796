import { DisplayModelEnum, LEDTemplateModeEnum } from 'app/config/constants';

/**
 * Entity class for template group led
 */
export class TemplateGroupLED {
  /**
   * template group id
   */
  id: Number;
  /**
   * name's template group
   */
  name: string;
  /**
   *  width's template group
   */
  width: number;
  /**
   * height's template group
   */
  height: number;
  /**
   * creation time's template group
   */
  creationTime: string;
  /**
   * template mode
   */
  templateMode: LEDTemplateModeEnum;
  /**
   * display model
   */
  displayModel: DisplayModelEnum;

  constructor(
    id?: Number,
    name?: string,
    width?: number,
    height?: number,
    creationTime?: string,
    templateMode?: LEDTemplateModeEnum,
    displayModel?: DisplayModelEnum
  ) {
    this.id = id;
    this.name = name;
    this.width = width;
    this.creationTime = creationTime;
    this.height = height;
    this.templateMode = templateMode;
    this.displayModel = displayModel;
  }
}
