import { ScheduleRegistration } from './schedule-registration';
import { Label } from './label';

export class ScheduleRegistrationDetail {
  /**
   * id
   */
  id: Number;
  /**
   * no
   */
  no: string;
  /**
   * suffix
   */
  suffix: string;
  /**
   * name
   */
  name: string;
  /**
   * headers
   */
  headers: string[];
  /**
   * schedules
   */
  schedules: ScheduleRegistration[];
  /**
   * userId
   */
  userId: Number;
  /**
   * routeId
   */
  routeId: Number;
  /**
   * true if timetable is checked
   */
  isChecked: boolean;
  /**
   * true if timetable is edited
   */
  isEdit: boolean;
  /**
   * Label
   */
  label: Label;

  constructor(id?: Number, no?: string, suffix?: string, name?: string, header?: string[], userId?: Number, label?: Label, routeId?: any) {
    this.id = id;
    this.no = no;
    this.suffix = suffix;
    this.name = name;
    this.headers = header;
    this.userId = userId;
    this.label = label;
    this.routeId = routeId;
  }
}
