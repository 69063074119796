import { Media } from './media';

/**
 * Entity class for index word
 */
export class IndexWord {
  /**
   * index word's ID
   */
  id: Number;
  /**
   * index word group's ID
   */
  groupId: Number;
  /**
   * index word name
   */
  name: string;
  /**
   * media
   */
  media: Media;
  /**
   * text content
   */
  text: string;
  /**
   * orderNo's indexWord
   */
  orderNo: number;
  constructor(groupId?: Number, name?: string, text?: string, id?: Number) {
    this.id = id;
    this.groupId = groupId;
    this.name = name;
    this.text = text;
    this.media = undefined;
  }
}
