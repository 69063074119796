import { LEDTemplateModeEnum, ScrollDirectionsEnum, ScrollStatusEnum, SpeedEnum } from 'app/config/constants';

/**
 * Entity class for area led
 */
export abstract class AreaLED {
  /**
   *
   */
  id: Number;
  /**
   *
   */
  name: string;
  /**
   *
   */
  index: number;
  /**
   *
   */
  isFix: boolean;
  /**
   *
   */
  posX: number;
  /**
   *
   */
  posY: number;
  /**
   *
   */
  width: number;
  /**
   *
   */
  height: number;
  /**
   *
   */
  canvas: any;
  /**
   * reference point to calculate co-ordinator
   */
  referencePoint: number;
  /**
   * true if hidden area
   */
  isHidden: boolean;
  /**
   * scroll: on / off / auto
   * default: off
   */
  scrollStatus: ScrollStatusEnum;
  /**
   * only setting when scroll status is on/auto
   * default: 50
   */
  scrollSpeed: number;
  /**
   * only setting when scroll status is on/auto
   * direction'scroll: left, right, up, down, semi-up, semi-down
   * default: left
   */
  scrollDirection: ScrollDirectionsEnum;

  /**
   * only setting when direction is up/down/semi-up/semi-down
   * default: 5
   */
  pauseTime: number;
  /**
   * Template id
   */
  templateId: number;
  isEditName: boolean;

  isSelected: boolean;
  symbol: any;
  areaPosXs: Array<number>;
  /**
   * Position x scroll
   */
  public posXScroll: number = 0;
  /**
   * Position y scroll
   */
  public posYScroll: number = 0;
  /**
   * Times scroll
   */
  public timesScroll: number = 0;

  constructor(name?: string, width?: number, height?: number, posX?: number, posY?: number, index?: number, isFix?: boolean) {
    this.name = name;
    this.width = width;
    this.height = height;
    this.posX = posX;
    this.posY = posY;
    this.index = index;
    this.isFix = isFix;
    this.referencePoint = 0;
    this.isHidden = false;
    this.isEditName = false;
    this.isSelected = false;
    this.scrollStatus = ScrollStatusEnum.OFF;
    this.scrollSpeed = SpeedEnum.LOW;
    this.scrollDirection = ScrollDirectionsEnum.LEFT;
    this.areaPosXs = [];
    if (!this.symbol) {
      this.symbol = Symbol();
    }
  }

  abstract checkTypeTextArea(): boolean;
  abstract getArea(): any;

  /**
   * get displayed X position
   */
  public get displayPosX() {
    switch (this.referencePoint) {
      case 0:
      case 3:
      case 6:
        return this.posX;
      case 1:
      case 4:
      case 7:
        return Math.ceil(this.posX + this.width / 2);
      case 2:
      case 5:
      case 8:
        return this.posX + this.width;
      default:
        return 0;
    }
  }

  /**
   * set displayed X position
   */
  public set displayPosX(value: number) {
    switch (this.referencePoint) {
      case 0:
      case 3:
      case 6:
        this.posX = value;
        break;
      case 1:
      case 4:
      case 7:
        this.posX = Math.ceil(value - this.width / 2);
        break;
      case 2:
      case 5:
      case 8:
        this.posX = value - this.width;
        break;
      default:
        break;
    }
  }

  /**
   * get displayed Y position
   */
  public get displayPosY() {
    switch (this.referencePoint) {
      case 0:
      case 1:
      case 2:
        return this.posY;
      case 3:
      case 4:
      case 5:
        return Math.ceil(this.posY + this.height / 2);
      case 6:
      case 7:
      case 8:
        return this.posY + this.height;
      default:
        return 0;
    }
  }

  /**
   * set displayed Y position
   */
  public set displayPosY(value: number) {
    switch (this.referencePoint) {
      case 0:
      case 1:
      case 2:
        this.posY = value;
        break;
      case 3:
      case 4:
      case 5:
        this.posY = Math.ceil(value - this.height / 2);
        break;
      case 6:
      case 7:
      case 8:
        this.posY = value - this.height;
        break;
      default:
        break;
    }
  }
}
