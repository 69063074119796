import { BusStopRoute } from './bus-stop-route';
import { TemplateLED } from './template-led';

export class RouteDestination {
  /**
   * route's id
   */
  id: Number;
  /**
   * true if route is checked, and vice versa
   */
  isChecked: boolean;
  /**
   * label
   */
  label: string;
  /**
   * route No.
   */
  routeNo: string;
  /**
   * Suffix
   */
  suffix: string;
  /**
   * route Name
   */
  routeName: string;
  /**
   * adjusted route no
   */
  adjustedRouteNo: string;
  /**
   * adjusted route name
   */
  adjustedRouteName: string;
  /**
   * Id template 1
   */
  idTemplate1: number;
  /**
   * Id template 2
   */
  idTemplate2: number;
  /**
   * Id template 3
   */
  idTemplate3: number;
  /**
   * Id template 4
   */
  idTemplate4: number;
  /**
   * Id template 5
   */
  idTemplate5: number;
  /**
   * Id template 6
   */
  idTemplate6: number;
  /**
   * template display 1
   */
  display1: TemplateLED;
  /**
   * template display 2
   */
  display2: TemplateLED;
  /**
   * template display 3
   */
  display3: TemplateLED;
  /**
   * template display 4
   */
  display4: TemplateLED;
  /**
   * template display 5
   */
  display5: TemplateLED;
  /**
   * template display 6
   */
  display6: TemplateLED;
  /**
   * list bus stop
   */
  busStops: Array<BusStopRoute>;

  /**
   * Template names to display
   */
  templateNamesToDisplay: Array<string>;

  /**
   * Is edit adjusted no
   */
  isEditAdjustedNo: boolean;

  /**
   * Is edit adjusted name
   */
  isEditAdjustedName: boolean;

  /**
   * Is show icon multi display
   */
  isShowIconMultiDisplay: boolean;

  /**
   * Template name show in GUI
   */
  templateNameShowInGUI: string;

  /**
   * Template ids to display
   */
  templateIdsToDisplay: Array<Number>;

  constructor(
    id?: Number,
    label?: string,
    routeNo?: string,
    suffix?: string,
    routeName?: string,
    adjustedRouteNo?: string,
    adjustedRouteName?: string,
    display1?: TemplateLED,
    busStops?: Array<BusStopRoute>
  ) {
    this.id = id;
    this.label = label;
    this.routeNo = routeNo;
    this.suffix = suffix;
    this.routeName = routeName;
    this.adjustedRouteNo = adjustedRouteNo;
    this.adjustedRouteName = adjustedRouteName;
    this.display1 = display1;
    this.busStops = busStops;
  }
}
