import { CustomTag } from './custom-tag';

export class ConditionMedia {
  deviceIds: Array<Number>;
  playlistIds: Array<Number>;
  mediaTypes: Array<string>;
  fileName: string;
  customTag: CustomTag;
  customTagElementId: number;
  constructor() {}
}
