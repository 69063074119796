/**
 * class PublishData
 */
export class PublishData {
  name: string;
  validFrom: string;

  constructor(name?: string, validFrom?: string) {
    this.name = name;
    this.validFrom = validFrom;
  }
}
