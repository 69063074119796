import { DisplayTemplate } from './display-template';
import { StyleDetail } from './style-detail';
import { Template } from './template';

/**
 * Entity class for style
 */
export class Style {
  /**
   * style id
   */
  id: Number;
  /**
   * style name
   */
  name: string;
  /**
   * style no
   */
  styleNo: string;
  /**
   * suffix
   */
  suffix: string;
  /**
   * display1 templates
   */
  display1Templates: Array<Template>;
  /**
   * display2 templates
   */
  display2Templates: Array<Template>;
  /**
   * displayTemplate1
   */
  displayTemplate1: DisplayTemplate;
  /**
   * displayTemplate2
   */
  displayTemplate2: DisplayTemplate;
  /**
   * project's id
   */
  projectId: Number;
  /**
   * style details for style
   */
  styleDetails: Array<StyleDetail>;
  /**
   * true if style is checked
   */
  isChecked: boolean;
  /**
   * true if style is editted
   */
  isEdit: boolean;

  constructor(id?: Number, name?: string, styleNo?: string, suffix?: string, projectId?: Number) {
    this.id = id;
    this.name = name;
    this.styleNo = styleNo;
    this.suffix = suffix;
    this.projectId = projectId;
  }
}
