/**
 * class entity for device screen size
 */
export class DeviceScreenSize {
  /**
   * width
   */
  width: number;
  /**
   * height
   */
  height: number;

  constructor(width?: number, height?: number) {
    this.width = width;
    this.height = height;
  }
}
