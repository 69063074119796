import { ContentDay } from 'app/model/entity/content-day';
/**
 * class UserCalendar
 */
export class UserCalendar {
  /**
   * id
   */
  id: Number;
  /**
   * name
   */
  name: string;
  /**
   * userId
   */
  userId: string;
  /**
   * status
   */
  status: boolean;
  /**
   *
   */
  calendars: Array<ContentDay>;

  routeId: any;
}
