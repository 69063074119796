import {
  AlignmentEnum,
  ArrivalTimeFormatEnum,
  Constant,
  LinkDataTextEnum,
  OrientationEnum,
  ScrollDirectionsEnum,
  ScrollStatusEnum,
  TemplateModeEnum
} from '../../config/constants';
import { Area } from './area';

/**
 * Entity class for text area
 */
export class TextArea extends Area {
  /**
   * text's text area
   */
  text: string;
  /**
   * font size's text
   */
  fontSize: number;
  /**
   * font name's text
   */
  fontName: string;
  /**
   * bold style'text
   */
  isBold: boolean;
  /**
   * italic style'text
   */
  isItalic: boolean;
  /**
   * align horizontal: left, center, right
   * default: left
   */
  horizontalTextAlignment: AlignmentEnum;
  /**
   * align vertical: top, middle, bottom
   * default: top
   */
  verticalTextAlignment: AlignmentEnum;
  /**
   * text orientation: horizontal, vertical, sideways
   * default: horizontal
   */
  orientation: OrientationEnum;
  /**
   * scroll: on / off
   * default: off
   */
  scrollStatus: ScrollStatusEnum;

  /**
   * only setting when scroll status is on
   * default: 50
   */
  scrollSpeed: number;
  /**
   * direction'scroll: left, right, up, down
   * default: left
   */
  scrollDirection: ScrollDirectionsEnum;

  /**
   * link reference data: clock, route no., route name, bus stop name, index word, position driven, external source
   * default: route name
   */
  linkReferenceData: LinkDataTextEnum;
  /**
   *
   */
  fontColor: string;
  /**
   *
   */
  backgroundColor: string;
  /**
   *
   */
  timesFileEnd: number;

  arrivalTimeFormat: ArrivalTimeFormatEnum;

  public posXScroll: number = 0;
  public posYScroll: number = 0;
  public timesScroll: number = 0;

  /**
   * stop duration (on/auto)
   */
  stopDuration: number;
  /**
   * Scroll direction options
   */
  public scrollDirectionOptions: TimetableScrollDirectionOption[];
  /**
   * True if Alignment setting of area is center and middle
   */
  public isHiddenScrollSetting: boolean = false;

  constructor(
    name?: string,
    width?: number,
    height?: number,
    posX?: number,
    posY?: number,
    index?: number,
    isFix?: boolean,
    isURL?: boolean,
    colorTextName?: string,
    text?: string,
    linkReferenceData?: LinkDataTextEnum,
    templateMode?: TemplateModeEnum
  ) {
    super(name, width, height, posX, posY, index, isFix, isURL, colorTextName, templateMode);
    this.text = text;
    this.fontSize = 50;
    this.fontName = 'Arial';
    this.isBold = false;
    this.isItalic = false;
    this.horizontalTextAlignment = AlignmentEnum.LEFT;
    this.verticalTextAlignment = AlignmentEnum.TOP;
    this.orientation = OrientationEnum.HORIZONTAL;
    this.scrollStatus = ScrollStatusEnum.OFF;
    this.scrollSpeed = Constant.SCROLL_SPEED_DEFAULT;
    this.scrollDirection = ScrollDirectionsEnum.LEFT;
    this.linkReferenceData = linkReferenceData;
    this.fontColor = Constant.FONT_COLOR_DEFAULT;
    this.backgroundColor = Constant.BACKGROUND_COLOR_DEFAULT;
    this.timesFileEnd = 1;
    this.stopDuration = Constant.STOP_DURATION_DEFAULT;
  }

  /**
   * override from area class
   * check type area
   * return true if area is text area
   */
  checkTypeTextArea(): boolean {
    return true;
  }

  /**
   * override from area class
   * get text area
   */
  getArea() {
    return this;
  }
}

/**
 * Timetable scroll direction option
 */
export class TimetableScrollDirectionOption {
  scrollDirection: ScrollDirectionsEnum;
  name: string;

  constructor(scrollDirection: ScrollDirectionsEnum, name: string) {
    this.scrollDirection = scrollDirection;
    this.name = name;
  }
}
