import { Label } from '../label';
import { TimetableScheduleMerge } from './timetable-schedule';

export class Timetable {
  id: Number;
  no: string;
  suffix: string;
  name: string;
  headers: string[];
  schedules: TimetableScheduleMerge[];
  /**
   * isEdit
   */
  isEdit: boolean;
  /**
   * isChecked
   */
  isChecked: boolean;
  constructor(id: Number, no: string, suffix: string, name: string, headers: string[]) {
    this.id = id;
    this.no = no;
    this.suffix = suffix;
    this.name = name;
    this.headers = headers;
  }
}
