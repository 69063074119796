import { Area } from './area';
import { Media } from './media';

/**
 * Entity class for style detail
 */
export class StyleDetail {
  /**
   * id
   */
  id: Number;
  /**
   * area display1 id
   */
  areaDisplayId1: Number;
  /**
   * area display2 id
   */
  areaDisplayId2: Number;
  /**
   * area display 1
   */
  areaDisplay1: Area;
  /**
   * area display 2
   */
  areaDisplay2: Area;
  /**
   * sound
   */
  mediaSound: Media;
  /**
   * main media
   */
  mediaMain: Media;
  /**
   * sound's id
   */
  mediaSoundId: Number;
  /**
   * main media's id
   */
  mediaMainId: Number;
  /**
   * text
   */
  text: string;
  /**
   * style id
   */
  styleId: Number;
  /**
   * true if area display 1 or area display 2 is TextArea
   */
  hasText: boolean;

  symbol: Symbol;

  constructor(styleId?: Number, areaDisplay1?: Area, areaDisplayId1?: Number) {
    this.areaDisplay1 = areaDisplay1;
    this.areaDisplayId1 = areaDisplayId1;
    this.styleId = styleId;
    if (!this.symbol) {
      this.symbol = Symbol();
    }
  }
}
