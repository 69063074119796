import { ItemDetail } from './item-detail';

export class TimetableSchedule {
  id: Number;
  timetableId: Number;
  scheduleJson: string;
  changeoverOffset: number;

  itemDetails: Array<ItemDetail>;
  headers: Array<string>;
  items: Array<string>;
  itemNames: any;
  hasItemNames: boolean;
  isChangeScheduleHeader: boolean;

  constructor() {
    this.headers = new Array<string>();
  }
}
