import { Area } from './area';
import { v4 as uuidv4 } from 'uuid';

export class ExternalContentDetail {
  id: Number;
  area: Area;
  source: number;
  externalContentId: Number;
  uuid: any;
  constructor(area?: Area, source?: number, externalContentId?: Number) {
    this.area = area;
    this.source = source;
    this.externalContentId = externalContentId;
    this.uuid = uuidv4();
  }
}
