import { Component, OnInit } from '@angular/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-schedule-merge',
  templateUrl: './menu-schedule-merge.component.html',
  styleUrls: ['./menu-schedule-merge.component.scss']
})
export class MenuScheduleMergeComponent implements OnInit {
  FIELD_COMPONENT = FIELD_COMPONENT;
  MODULE_NAME = MODULE_NAME;

  isChosenTabDaily: boolean;
  isChosenTabMonthly: boolean;
  isChosenTabConfirmation: boolean = true;
  isCheckWidthMenu: boolean;
  isCheckMerge: boolean;
  isCheckUpdateData: boolean;
  isCheckCreateDailySchedule: boolean;
  isRecordsTimetableMax: boolean;
  isEditingTimetable: boolean;
  isPreviewScheduleMerge: boolean;
  isRoot: boolean;
  constructor(private menuActionService: MenuActionService, private dataService: DataService) {
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_TAB_DAILY) {
        this.isChosenTabDaily = <boolean>data[1];
      }
      if (data[0] == Constant.IS_TAB_MONTHLY) {
        this.isChosenTabMonthly = <boolean>data[1];
      }
      if (data[0] == Constant.IS_TAB_CONFIRMATION) {
        this.isChosenTabConfirmation = <boolean>data[1];
      }
      if (data[0] == Constant.IS_RECORDS_TIMETABLE_MAX) {
        this.isRecordsTimetableMax = <boolean>data[1];
      }
      if (data[0] == 'isEditingTimetable') {
        this.isEditingTimetable = <boolean>data[1];
      }
      if (data[0] == 'isPreviewScheduleMerge') {
        this.isPreviewScheduleMerge = <boolean>data[1];
      }
      if (data[0] == Constant.IS_ROOT) {
        this.isRoot = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {
    this.isChosenTabConfirmation = true;
    this.handlingDisplayMenu();
  }

  /**
   * execute action for import timetable in menu
   * @param {string} moduleName current module name
   */
  public importScheduleMerge(moduleName: string) {
    this.menuActionService.importScheduleMerge(moduleName);
  }

  /**
   * execute action for export timetable in menu
   * @param {string} moduleName current module name
   */
  public exportTimetable(moduleName: string) {
    this.menuActionService.exportTimetable(moduleName);
  }

  /**
   * execute action for Add in menu
   * @param {string} moduleName current module name
   */
  public add(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  public edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for delete in menu
   * @param {string} moduleName current module name
   */
  public deleteTimetable(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for Duplicate in menu
   * @param {string} moduleName current module name
   */
  public duplicate(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }

  /**
   * execute action for change Template in menu
   * @param {string} moduleName current module name
   */
  public changeTemplate(moduleName: string) {
    this.menuActionService.changeTemplate(moduleName);
  }

  /**
   * execute action for change Template in menu
   * @param {string} moduleName current module name
   */
  public duplicateSetting(moduleName: string) {
    this.menuActionService.duplicateSetting(moduleName);
  }

  /**
   * execute action for set free area in menu
   * @param {string} moduleName current module name
   */
  public setFreeArea(moduleName: string) {
    this.menuActionService.setFreeArea(moduleName);
  }

  /**
   * execute action for clear field free area in menu
   * @param {string} moduleName current module name
   */
  public clearField(moduleName: string) {
    this.menuActionService.clearField(moduleName);
  }

  /**
   * toggle sort and filter item
   * @param {string} moduleName module name
   */
  public toggleSortAndFilter(moduleName: string) {
    if (this.isChosenTabDaily) {
      this.menuActionService.toggleSortAndFilterDaily(moduleName);
    } else if (this.isChosenTabMonthly) {
      this.menuActionService.toggleSortAndFilter(moduleName);
    }
  }

  /**
   * execute action for Update Timing in menu
   * @param {string} moduleName current module name
   */
  public updateTime(moduleName: string) {
    this.menuActionService.updateTime(moduleName);
  }
  /**
   * execute action for Channel Area Preview in menu
   * @param {string} moduleName current module name
   */
  public channelAreaPreview(moduleName: string) {
    this.menuActionService.channelAreaPreview(moduleName);
  }

  /**
   * execute action for change date line in menu
   * @param {string} moduleName current module name
   */
  public changDateLine(moduleName: string) {
    this.menuActionService.changeDateLine(moduleName);
  }

  /**
   * execute action for switching timing area
   * @param moduleName current module name
   */
  public switchArea(moduleName: string) {
    this.menuActionService.switchTimingArea(moduleName);
  }

  /**
   * schedule Merge
   * @param moduleName
   */
  public updateData(moduleName: string) {
    this.menuActionService.updateData(moduleName);
  }

  /**
   * schedule Merge
   * @param moduleName
   */
  public mergeSchedule(moduleName: string) {
    this.menuActionService.mergeSchedule(moduleName);
  }
  /**
   * handle  menu  when resize screen
   */
  private handlingDisplayMenu(): void {
    this.isCheckWidthMenu = true;
    this.isCheckMerge = true;
    this.isCheckUpdateData = true;
    this.isCheckCreateDailySchedule = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'checkMergeSchedule') {
        this.isCheckMerge = <boolean>data[1];
      }
      if (data[0] == 'checkUpdateDataScheduleMerge') {
        this.isCheckUpdateData = <boolean>data[1];
      }
      if (data[0] == 'checkCreateDailyScheduleMerge') {
        this.isCheckCreateDailySchedule = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckUpdateData && this.isCheckCreateDailySchedule && this.isCheckMerge;
    });
  }
  /**
   * execute action for Update Timing in menu
   * @param {string} moduleName current module name
   */
  public updateTextHighlightSetting(moduleName: string) {
    this.menuActionService.updateTextHighlightSetting(moduleName);
  }
}
