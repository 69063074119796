import { Area } from './area';
import { ExternalContentDetail } from './external-content-detail';

export class NewsContentDetail extends ExternalContentDetail {
  texts: Array<string>;

  constructor(area?: Area, source?: number, externalContentId?: Number) {
    super(area, source, externalContentId);
  }
}
