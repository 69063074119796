import { Component, OnInit } from '@angular/core';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';

@Component({
  selector: 'menu-schedule-registration',
  templateUrl: './menu-schedule-registration.component.html',
  styleUrls: ['./menu-schedule-registration.component.scss']
})
export class MenuScheduleRegistrationComponent implements OnInit {
  FIELD_COMPONENT = FIELD_COMPONENT;
  MODULE_NAME = MODULE_NAME;
  /**
   * is tab timetable
   */
  isTabTimetable: boolean = true;
  /**
   * is tab calendar
   */
  isTabCalendar: boolean;
  /**
   * Tab_Enum
   */
  public Tab_Enum = Tab_Enum;
  /**
   * is check width menu
   */
  isCheckWidthMenu: boolean;

  /**
   * is check register
   */
  isCheckRegister: boolean;

  isRecordsTimetableMax: boolean;
  isEditingTimetable: boolean;
  isPreviewScheduleRegistration: boolean;
  isUserRoot: boolean;

  constructor(private menuActionService: MenuActionService, private dataService: DataService) {
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_TAB_CALENDAR) {
        this.isTabCalendar = <boolean>data[1];
      }
      if (data[0] == Constant.IS_TAB_TIMETABLE) {
        this.isTabTimetable = <boolean>data[1];
      }
      if (data[0] == Constant.IS_RECORDS_TIMETABLE_MAX) {
        this.isRecordsTimetableMax = <boolean>data[1];
      }
      if (data[0] == 'isEditingTimetable') {
        this.isEditingTimetable = <boolean>data[1];
      }
      if (data[0] == 'isPreviewScheduleRegistration') {
        this.isPreviewScheduleRegistration = <boolean>data[1];
      }
      if (data[0] == Constant.USER_ROOT) {
        this.isUserRoot = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {
    this.isTabTimetable = true;
    this.handlingDisplayMenu();
  }
  /**
   * handle  menu  when resize screen
   */
  private handlingDisplayMenu(): void {
    this.isCheckWidthMenu = true;
    this.isCheckRegister = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'checkRegister') {
        this.isCheckRegister = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckRegister;
    });
  }

  /**
   * execute action for change Template in menu
   * @param {string} moduleName current module name
   */
  public changeTemplate(moduleName: string) {
    this.menuActionService.changeTemplate(moduleName);
  }

  /**
   * execute action for change Template in menu
   * @param {string} moduleName current module name
   */
  public routeSetting(moduleName: string) {
    this.menuActionService.routeSetting(moduleName);
  }

  /**
   * import schedule registration
   * @param moduleName
   */
  importScheduleRegistration(moduleName: string) {
    this.menuActionService.importScheduleRegistration(moduleName);
  }

  updateDataRegistration(moduleName: string) {
    this.menuActionService.updateDataRegistration(moduleName);
  }
  /**
   * export schedule registration
   * @param moduleName
   */
  exportScheduleRegistration(moduleName: string) {
    this.menuActionService.exportScheduleRegistration(moduleName);
  }

  /**
   * export calendar
   * @param moduleName
   */
  exportCalendarScheduleRegistration(moduleName: string) {
    this.menuActionService.exportCalendarScheduleRegistration(moduleName);
  }

  /**
   * add timetable
   * @param moduleName
   */
  add(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * add timetable
   * @param moduleName
   */
  edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * add timetable
   * @param moduleName
   */
  duplicate(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }

  /**
   * add timetable
   * @param moduleName
   */
  delete(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * toggle sort and filter item
   * @param {string} moduleName module name
   */
  toggleSortAndFilter(moduleName: string) {
    this.menuActionService.toggleSortAndFilter(moduleName);
  }

  /**
   * manage label
   * @param {string} moduleName module name
   */
  manageLabel(moduleName: string) {
    this.menuActionService.manageLabel(moduleName);
  }

  /**
   * change label item
   * @param {string} moduleName current module name
   */
  changeLabel(moduleName: string) {
    this.menuActionService.changeLabel(moduleName);
  }

  /**
   * execute action for Channel Area Preview in menu
   * @param {string} moduleName current module name
   */
  channelAreaPreview(moduleName: string) {
    this.menuActionService.channelAreaPreview(moduleName);
  }
}

export enum Tab_Enum {
  TIMETABLE,
  CALENDAR
}
