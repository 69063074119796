import { TemplateModeEnum } from 'app/config/constants';

/**
 * Entity class for template group
 */
export class TemplateGroup {
  /**
   * template group id
   */
  id: Number;
  /**
   * name's template group
   */
  name: string;
  /**
   *  width's template group
   */
  width: number;
  /**
   * height's template group
   */
  height: number;

  /**
   * creation time's template group
   */
  creationTime: string;
  /**
   * template mode
   */
  templateMode: TemplateModeEnum;
  /**
   * templateModeString
   */
  templateModeString: string;

  constructor(name?: string, width?: number, height?: number, creationTime?: string, templateMode?: TemplateModeEnum) {
    this.name = name;
    this.width = width;
    this.height = height;
    this.creationTime = creationTime;
    this.templateMode = templateMode;
  }
}
