import {
  DestinationEnum,
  DisplayTimingTypeEnum,
  OnClickEventTypeEnum,
  ReferenceColumnEnum,
  ReferencePositionColumnEnum,
  ReferencePositionRowEnum,
  TemplateModeEnum,
  TemplateTypeEnum,
  TimingOffEnum,
  TimingOnEnum
} from '../../config/constants';

/**
 * Entity class for area
 */
export abstract class Area {
  /**
   *
   */
  id: Number;
  /**
   *
   */
  name: string;
  /**
   *
   */
  isFix: boolean;
  /**
   *
   */
  index: number;
  /**
   *
   */
  posX: number;
  /**
   *
   */
  posY: number;
  /**
   *
   */
  width: number;
  /**
   *
   */
  height: number;
  /**
   *
   */
  canvas: any;
  /**
   * reference point to calculate co-ordinator
   */
  referencePoint: number;
  /**
   *
   */
  indexWordGroupId: Number;
  /**
   *
   */
  referencePositionRow: any;
  /**
   *
   */
  referencePositionColumn: any;
  /**
   *
   */
  isOnClickEvent: boolean;
  /**
   *
   */
  onClickEventType: OnClickEventTypeEnum;
  /**
   *
   */
  onClickEventDestination: DestinationEnum;
  /**
   *
   */
  timingOn: TimingOnEnum;
  /**
   *
   */
  timingOff: TimingOffEnum;
  /**
   *
   */
  isHidden: boolean;
  /**
   *
   */
  isEditName: boolean;
  /**
   *
   */
  isSelected: boolean;
  /**
   *
   */
  symbol;
  /**
   *
   */
  isOn: boolean;
  /**
   *
   */
  isActive: boolean = true;

  durationDisplayTiming: number;
  /**
   *
   */
  isChangePosition: boolean;
  isOffEvent: boolean = false;
  isChangeTemplateCount: boolean = false;

  templateType: TemplateTypeEnum;

  /**
   * true if display timing ON
   */
  isTimingOn: boolean;
  /**
   * display timing type
   */
  timingType: DisplayTimingTypeEnum;

  /**
   * uuidV4 image
   */
  uuidV4Image: any;

  /**
   * uuidV4 sound A
   */
  uuidV4SoundA: any;

  /**
   * uuidV4 sound B
   */
  uuidV4SoundB: any;

  /**
   * Group Id
   */
  groupId: string;

  /**
   *width skewed
   */
  widthSkewed: any;

  /**
   *height skewed
   */
  heightSkewed: any;
  /**
   * uuid
   */
  uuid: string;
  /**
   * timetableId
   */
  timetableId: string;
  /**
   * referenceColum
   */
  referenceColumn: any;

  /**
   *
   */
  isURL: boolean;

  /**
   * colorTextName
   */
  colorTextName: string;

  /**
   * type of page count
   */
  typePageCount: number;

  idWithDisplay: string;
  constructor(
    name?: string,
    width?: number,
    height?: number,
    posX?: number,
    posY?: number,
    index?: number,
    isFix?: boolean,
    isURL?: boolean,
    colorTextName?: string,
    templateMode?: TemplateModeEnum
  ) {
    this.name = name;
    this.width = width;
    this.height = height;
    this.posX = posX;
    this.posY = posY;
    this.index = index;
    this.isFix = isFix;
    this.isURL = isURL;
    this.colorTextName = colorTextName;
    this.referencePoint = 0;
    this.isHidden = false;
    this.isEditName = false;
    this.isSelected = false;
    this.isChangePosition = false;
    this.isOnClickEvent = false;
    this.onClickEventType = null;
    this.onClickEventDestination = null;
    this.referencePositionColumn = ReferencePositionColumnEnum.INDEX_WORD_1;
    this.referencePositionRow = ReferencePositionRowEnum.CURRENT_POSITION;
    if (templateMode == TemplateModeEnum.ON_BUS_DISPLAY) {
      this.timingOn = TimingOnEnum.FROM_THE_BEGINNING;
      this.timingOff = TimingOffEnum.TO_THE_END;
      this.durationDisplayTiming = 10;
    }
    if (templateMode == TemplateModeEnum.TIMETABLE) {
      this.referenceColumn = ReferenceColumnEnum.ITEM_1;
    }
    if (!this.symbol) {
      this.symbol = Symbol();
    }
    this.isTimingOn = isFix ? false : null;
    this.timingType = null;
  }

  abstract checkTypeTextArea(): boolean;
  abstract getArea(): any;

  /**
   * get displayed X position
   */
  public get displayPosX() {
    switch (this.referencePoint) {
      case 0:
      case 3:
      case 6:
        return this.posX;
      case 1:
      case 4:
      case 7:
        return Math.ceil(this.posX + this.width / 2);
      case 2:
      case 5:
      case 8:
        return this.posX + this.width;
      default:
        return 0;
    }
  }

  /**
   * set displayed X position
   */
  public set displayPosX(value: number) {
    switch (this.referencePoint) {
      case 0:
      case 3:
      case 6:
        this.posX = value;
        break;
      case 1:
      case 4:
      case 7:
        this.posX = Math.ceil(value - this.width / 2);
        break;
      case 2:
      case 5:
      case 8:
        this.posX = value - this.width;
        break;
      default:
        break;
    }
  }

  /**
   * get displayed Y position
   */
  public get displayPosY() {
    switch (this.referencePoint) {
      case 0:
      case 1:
      case 2:
        return this.posY;
      case 3:
      case 4:
      case 5:
        return Math.ceil(this.posY + this.height / 2);
      case 6:
      case 7:
      case 8:
        return this.posY + this.height;
      default:
        return 0;
    }
  }

  /**
   * set displayed Y position
   */
  public set displayPosY(value: number) {
    switch (this.referencePoint) {
      case 0:
      case 1:
      case 2:
        this.posY = value;
        break;
      case 3:
      case 4:
      case 5:
        this.posY = Math.ceil(value - this.height / 2);
        break;
      case 6:
      case 7:
      case 8:
        this.posY = value - this.height;
        break;
      default:
        break;
    }
  }
}
