import { DisplayModelEnum } from 'app/config/constants';
import { AreaLED } from '../led/area-led';

/**
 * Entity class for template led
 */
export class TemplateLED {
  /**
   * template id
   */
  id: Number;
  /**
   * template's name
   */
  name: string;
  /**
   * template's width
   */
  width: number;
  /**
   * template's height
   */
  height: number;
  /**
   * template group id
   */
  templateGroupId: Number;
  /**
   * template group name
   */
  templateGroupName: string;
  /**
   * Display model
   */
  displayModel: DisplayModelEnum;
  /**
   * template's layer list
   */
  areas: Array<AreaLED>;

  constructor(
    name?: string,
    width?: number,
    height?: number,
    templateGroupId?: Number,
    templateGroupName?: string,
    areas?: Array<AreaLED>
  ) {
    this.name = name;
    this.width = width;
    this.height = height;
    this.templateGroupId = templateGroupId;
    this.templateGroupName = templateGroupName;
    this.areas = areas;
  }
}
