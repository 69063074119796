/**
 * class operation information setting
 */
export class OperationInformationSetting {
  /**
   * id
   */
  id: Number;
  /**
   * text
   */
  text: string;
  /**
   * time
   */
  time: number;
  /**
   * index
   */
  index: number;
  /**
   * true if editing
   */
  isEdit: boolean;

  constructor(text?: string, time?: number, index?: number) {
    this.text = text;
    this.time = time;
    this.index = index;
  }
}
