import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionState } from 'app/config/action-state';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { Common } from 'app/model/entity/common';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'menu-announcement-manager',
  templateUrl: './menu-announcement-manager.component.html',
  styleUrls: ['./menu-announcement-manager.component.scss']
})
export class MenuAnnouncementManagerComponent implements OnInit {
  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;
  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;

  /**
   * is tab playlist
   */
  isTabPlaylist: boolean;

  /**
   * is tab create playlist
   */
  isTabCreatePlaylist: boolean;

  /**
   * is tab translate
   */
  isTabTranslate: boolean;

  /**
   * is tab common playlist
   */
  isTabCommonPlaylist: boolean;

  /**
   * is tab register playlist
   */
  isTabRegisterPlaylist: boolean;
  /**
   * true if choose tab playlist
   */
  isDisabledOption: boolean;
  /**
   * true if edit playlist
   */
  isEditPlaylist: boolean;
  /**
   * true if edit folder
   */
  isEditFolder: boolean;
  /**
   * true if media of sequence is selected
   */
  isSelectedMediaOfSequence: boolean;

  /**
   * true if audio silence is selected
   */
  isSelectedAudioSilence: boolean;
  /**
   * action states for module 1
   */
  actionState1: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);

  public isCheckNetWork: boolean;
  /**
   * true if network connected successfully
   */
  public isNetworkConnect: boolean;

  /**
   * language key
   */
  public languageKey: boolean;

  /**
   * array subscription
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();

  /**
   * common object
   */
  private commonObject: Common;

  /**
   * isCheckWidthMenu
   */
  public isCheckWidthMenu: boolean;
  /**
   * isCheckDelivery
   */
  public isCheckDelivery: boolean;

  /**
   * is call api A01
   */
  public isCallAPIA01: boolean;

  constructor(
    private menuActionService: MenuActionService,
    private dataService: DataService,
    private commonService: CommonService,
    private translateService: TranslateService
  ) {
    this.dataService.currentData.subscribe(data => {
      switch (data[0]) {
        case Constant.IS_TAB_PLAYLIST:
          this.isTabPlaylist = <boolean>data[1];
          break;
        case Constant.IS_TAB_CREATE_PLAYLIST:
          this.isTabCreatePlaylist = <boolean>data[1];
          break;
        case Constant.IS_TAB_TRANSLATION_AND_SPEECH_SYNTHESIS:
          this.isTabTranslate = <boolean>data[1];
          break;
        case Constant.IS_TAB_COMMON_PLAYLIST:
          this.isTabCommonPlaylist = <boolean>data[1];
          break;
        case Constant.IS_TAB_REGISTER_PLAYLIST:
          this.isTabRegisterPlaylist = <boolean>data[1];
          break;
        case Constant.IS_NETWORK_OK:
          this.isNetworkConnect = <boolean>data[1];
          break;
        case Constant.IS_EDIT_PLAYLIST:
          this.isEditPlaylist = <boolean>data[1];
          break;
        case Constant.IS_EDIT_FOLDER:
          this.isEditFolder = <boolean>data[1];
          break;
        case Constant.IS_ON_PREVIEW:
          this.isDisabledOption = <boolean>data[1];
          break;
        case Constant.IS_DELETE_DISABLE:
          this.isSelectedMediaOfSequence = <boolean>data[1];
          break;
        case Constant.IS_AUTO_SILENCE:
          this.isSelectedAudioSilence = <boolean>data[1];
          break;
        case Constant.IS_CALL_API_A01:
          this.isCallAPIA01 = <boolean>data[1];
          break;
        default:
          break;
      }
    });

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multi language date picker
        this.languageKey = this.commonService.getCommonObject().setting?.language == 'jp' ? true : false;
      })
    );
  }

  ngOnInit(): void {
    this.handlingDisplayMenu();
    this.languageKey = this.commonService.getCommonObject().setting?.language == 'jp' ? true : false;
  }

  /**
   * execute action for import media in menu
   * @param {string} moduleName current module name
   */
  importMedia(moduleName: string) {
    this.menuActionService.importMedia(moduleName);
  }

  /**
   * execute action for Add playlist in menu
   * @param {string} moduleName current module name
   */
  addPlaylist(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for Edit playlist in menu
   * @param {string} moduleName current module name
   */
  editPlaylist(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for delete playlist in menu
   * @param {string} moduleName current module name
   */
  deletePlaylist(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for Duplicate playlist in menu
   * @param {string} moduleName current module name
   */
  duplicatePlaylist(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }

  /**
   * execute action for Add folder in menu
   * @param {string} moduleName current module name
   */
  addFolderMedia(moduleName: string) {
    this.menuActionService.addFolder(moduleName);
  }

  /**
   * execute action for Edit folder in menu
   * @param {string} moduleName current module name
   */
  editFolderMedia(moduleName: string) {
    this.menuActionService.editFolder(moduleName);
  }

  /**
   * execute action for Delete Item in menu
   * @param {string} moduleName current module name
   */
  deleteItem(moduleName: string) {
    this.menuActionService.deleteItem(moduleName);
  }

  /**
   * execute action for Undo in menu
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for Redo in menu
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }

  /**
   * execute action for delivery
   * @param {string} moduleName module name
   */
  delivery(moduleName: string) {
    this.menuActionService.delivery(moduleName);
  }

  /**
   * execute action for sync setting
   * @param {string} moduleName module name
   */
  autoRefreshAnnouncementPlaylist(moduleName: string) {
    this.menuActionService.autoRefreshAnnouncementPlaylist(moduleName);
  }

  /**
   * play timing setting
   * @param moduleName
   */
  playTimingSetting(moduleName: string) {
    this.menuActionService.playTimingSetting(moduleName);
  }

  /**
   * play timing setting
   * @param moduleName
   */
  specificSequenceSetting(moduleName: string) {
    this.menuActionService.specificSequenceSetting(moduleName);
  }

  /**
   * check NetWork
   */
  private handlingDisplayMenu() {
    this.isCheckNetWork = true;
    this.isCheckWidthMenu = true;
    this.isCheckDelivery = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]}:checkDelivery`) {
        this.isCheckDelivery = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]}:checkNetWork`) {
        this.isCheckNetWork = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckDelivery && this.isCheckNetWork;
    });
  }
}
