import { Media } from './media';

/**
 * Entity class for Text
 */
export class Text extends Media {

  content: string;

  constructor() {
    super();
  }

  getMedia() {
    return this;
  }
}
