import { ScheduleRegistrationDetail } from 'app/model/entity/schedule-registration-detail';
import { DailySchedule } from './playlist';
import { SimplePlaylist } from './simple/simple-playlist';
import { Timetable } from './timetable';

/**
 * entity class for a day of signage content
 */
export class ContentDay {
  id: Number;
  fullDate: Date;
  playlist: DailySchedule;
  isOtherMonth: boolean;
  inactive: boolean;
  isRepeated: boolean;
  startDate: Date;
  repeatMode: string;
  weekdayRepeat: Array<boolean>;
  finishDate: Date;
  timetable: Timetable;
  color: string;
  playlistSimple: SimplePlaylist;
  isDelivered: boolean;
  timetableId: Number;
  unlimitedInfo: {};
  playlistSimpleId: Number;
  day: number;
  scheduleRegistration: ScheduleRegistrationDetail;
  scheduleRegistrationId: Number;
  isRegistered: boolean;
  styleId: string;
  timetableName: string;
  routeId: any;

  constructor(day?: number, fullDate?: Date, inactive?: boolean, isOtherMonth?: boolean, playlist?: DailySchedule) {
    this.day = day;
    this.fullDate = fullDate;
    this.inactive = inactive;
    this.isOtherMonth = isOtherMonth;
    this.playlist = playlist;
    this.isDelivered = false;
    this.isRegistered = false;
  }

  public get dateInMonth(): string {
    return `${this.fullDate.getDate()}`;
  }
}
