import { Component, OnInit } from '@angular/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-destination-sign',
  templateUrl: './menu-destination-sign.component.html',
  styleUrls: ['./menu-destination-sign.component.scss']
})
export class MenuDestinationSignComponent implements OnInit {
  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;
  /**
   * True if is play preview
   */
  public isPlayPreview: boolean;
  /**
   * True if data is editing
   */
  public isEditData: boolean;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;
  constructor(private menuActionService: MenuActionService, private dataService: DataService) {
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_PLAY_PREVIEW) {
        this.isPlayPreview = <boolean>data[1];
      }
      if (data[0] == Constant.IS_EDIT_DATA) {
        this.isEditData = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {}

  /**
   * execute action for Data Reflection From Route List Editor in menu
   * @param {string} moduleName current module name
   */
  public reflectDataFromRouteListEditor(moduleName: string): void {
    this.menuActionService.reflectDataFromRouteListEditor(moduleName);
  }

  /**
   * execute action for import route list in menu
   * @param {string} moduleName current module name
   */
  public importRouteList(moduleName: string): void {
    this.menuActionService.importRouteList(moduleName);
  }

  /**
   * execute action for export route list in menu
   * @param {string} moduleName current module name
   */
  public exportRouteList(moduleName: string): void {
    this.menuActionService.exportRouteList(moduleName);
  }

  /**
   * execute action for Edit data Destination in menu
   * @param {string} moduleName current module name
   */
  public editDataDestination(moduleName: string): void {
    this.menuActionService.editDataDestination(moduleName);
  }

  /**
   * execute action for Delete route list destination in menu
   * @param {string} moduleName current module name
   */
  public deleteRouteListDestination(moduleName: string): void {
    this.menuActionService.deleteRouteListDestination(moduleName);
  }

  /**
   * execute action for change template route list destination in menu
   * @param {string} moduleName current module name
   */
  public changeTemplateRouteListDestination(moduleName: string): void {
    this.menuActionService.changeTemplateRouteListDestination(moduleName);
  }

  /**
   * execute action for delivery data destination in menu
   * @param {string} moduleName current module name
   */
  public delivery(moduleName: string): void {
    this.menuActionService.delivery(moduleName);
  }

  /**
   * execute action for download publish file data destination in menu
   * @param {string} moduleName current module name
   */
  public downloadPublishFile(moduleName: string): void {
    this.menuActionService.downloadPublishFile(moduleName);
  }
}
