import { Media } from './media';

/**
 * Entity class for Audio
 */
export class Sound extends Media {

    /**
     * bit rate (bit/s)
     */
    bitRate: number;

    /**
      * duration (s)
      */
    duration: number;

    constructor() {
        super();
    }

    getMedia() {
        return this;
    }
}
