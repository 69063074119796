import { DestinationEnum, TemplateTypeEnum } from 'app/config/constants';
import { Layer } from './layer';

/**
 * Entity class for template
 */
export class Template {
  /**
   * template id
   */
  id: Number;
  /**
   * template's name
   */
  name: string;
  /**
   * template's width
   */
  width: number;
  /**
   * template's height
   */
  height: number;
  /**
   * template group id
   */
  templateGroupId: Number;
  /**
   *
   */
  templateGroupName: string;
  /**
   * template's layer list
   */
  layers: Array<Layer>;

  /**
   * count
   */
  count: number = 0;

  /**
   * template type
   */
  templateType: TemplateTypeEnum;

  /**
   * is auto transition
   */
  isAutoTransition: boolean;

  /**
   * transition time
   */
  transitionTime: number;

  /**
   * destination
   */
  destination: DestinationEnum;

  /**
   * is multiple timetable
   */
  isMultiTimetable: boolean;

  /**
   * is Page switching
   */
  isPageSwitching: boolean;

  /**
   * page Row Number
   */
  pageRowNumber: number;

  /**
   * mode
   */
  modeActionTemplate: number;

  constructor(
    name?: string,
    width?: number,
    height?: number,
    templateGroupId?: Number,
    templateGroupName?: string,
    layers?: Array<Layer>,
    modeActionTemplate?: number
  ) {
    this.name = name;
    this.width = width;
    this.height = height;
    this.templateGroupId = templateGroupId;
    this.templateGroupName = templateGroupName;
    this.layers = layers;
    this.templateType = TemplateTypeEnum.MAIN;
    this.isAutoTransition = false;
    this.transitionTime = null;
    this.destination = null;
    this.isMultiTimetable = false;
    this.isPageSwitching = false;
    this.pageRowNumber = null;
    this.modeActionTemplate = null;
  }
}
