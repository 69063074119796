import { User } from '../../core/user/user.model';

/**
 * Entity class for project
 */
export class Project {
  /**
   * ID user
   */
  public id: Number;
  /**
   * project name
   */
  public name: string;
  /**
   * true if checked destination sign
   */
  public hasEds: boolean;
  /**
   * true if checked bus information
   */
  public hasBid: boolean;
  /**
   * true if checked signage channel
   */
  public hasDsc: boolean;
  /**
   * true if checked station
   */
  public hasBsd: boolean;
  /**
   * true if checked signage display
   */
  public hasSd: boolean;
  /**
   * Description
   */
  public description: string;
  /**
   * Time create Project
   */
  public creationTime: string;
  /**
   * project member
   */
  public projectMembers: Array<User>;
  /**
   * true if all members are chosen
   */
  isPublic: boolean;

  constructor() {}
}
