/**
 * class entity for delivery status timetable
 */
export class DeliveryStatusTimetable {
  /**
   * id
   */
  id: Number;
  /**
   * id of device delivery
   */
  deviceId: Number;
  /**
   * status delivery
   */
  status: string;
  /**
   * job's id of delivery job
   */
  jobId: string;
  /**
   * registration id of device
   */
  registrationId: string;
  /**
   * detail status of device
   */
  detailStatus: string;
  /**
   * detail status for display of device
   */
  detailStatusDisplay: string;
  /**
   * Downloaded files
   */
  downloadedFiles: string;
  /**
   * Total File
   */
  totalFile: string;

  constructor(deviceId: Number, status: string, jobId: string, registrationId: string) {
    this.deviceId = deviceId;
    this.status = status;
    this.jobId = jobId;
    this.registrationId = registrationId;
  }
}
