import { AnnouncementMedia } from './announcement/announcement-media';

/**
 * Entity class for index word
 */
export class SpeechSynthesis {
  /**
   * translationLanguageCode
   */
  translationLanguageCode: string;

  /**
   * language Name En
   */
  languageNameEn: string;

  /**
   * language Name Ja
   */
  languageNameJa: string;

  /**
   * polly_language_code
   */
  pollyLanguageCode: string;

  /**
   * target Language Code
   */
  targetLanguageCode: string;

  /**
   * text translated
   */
  translatedText: string;

  /**
   * text retranslated
   */
  retranslatedText: string;

  /**
   * speaker
   */
  speaker: any;

  /**
   * engine
   */
  engine: string;
  /**
   * rate
   */
  rate: string;

  /**
   * true if text has speech
   */
  isSpeech: boolean;

  /**
   * true if Speech Synthesis is check
   */
  isChecked: boolean;

  /**
   * isAvailable
   */
  isAvailable: boolean;

  /**
   * media
   */
  media: AnnouncementMedia;

  /**
   * true if change text translate
   */
  isChangeText: boolean;

  /**
   * true if change re text translate
   */
  isChangeReText: boolean;

  /**
   * true if change speaker
   */
  isChangeSpeaker: boolean;

  /**
   * true if change text rate
   */
  isChangeRate: boolean;

  /**
   * true if edit text
   */
  isEditText: boolean;

  /**
   * true if text input is allowed
   */
  isAllowsTextInput: boolean;

  constructor(
    translationLanguageCode?: string,
    languageNameEn?: string,
    languageNameJa?: string,
    pollyLanguageCode?: string,
    speaker?: any,
    rate?: string,
    isAvailable?: boolean
  ) {
    this.translationLanguageCode = translationLanguageCode;
    this.languageNameEn = languageNameEn;
    this.languageNameJa = languageNameJa;
    this.pollyLanguageCode = pollyLanguageCode;
    this.speaker = speaker;
    this.rate = rate;
    this.isAvailable = isAvailable;
  }
}
