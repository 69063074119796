import { DataExternalSetting } from './data-external-setting';

export class DisplayTemplate {
  idMainPage: Number;
  idSubPage1: Number;
  idSubPage2: Number;
  idSubPage3: Number;
  idSubPage4: Number;
  idSubPage5: Number;
  idSubPage6: Number;
  idSubPage7: Number;
  idSubPage8: Number;
  idSubPage9: Number;
  idEmergencyPage: Number;
  externalContentMainPage: DataExternalSetting[];
  externalContentPage1: DataExternalSetting[];
  externalContentPage2: DataExternalSetting[];
  externalContentPage3: DataExternalSetting[];
  externalContentPage4: DataExternalSetting[];
  externalContentPage5: DataExternalSetting[];
  externalContentPage6: DataExternalSetting[];
  externalContentPage7: DataExternalSetting[];
  externalContentPage8: DataExternalSetting[];
  externalContentPage9: DataExternalSetting[];
  externalContentEmergencyPage: DataExternalSetting[];

  constructor(
    idMainPage?: Number,
    idSubPage1?: Number,
    idSubPage2?: Number,
    idSubPage3?: Number,
    idSubPage4?: Number,
    idSubPage5?: Number,
    idSubPage6?: Number,
    idSubPage7?: Number,
    idSubPage8?: Number,
    idSubPage9?: Number,
    idEmergencyPage?: Number
  ) {
    this.idMainPage = idMainPage;
    this.idSubPage1 = idSubPage1;
    this.idSubPage2 = idSubPage2;
    this.idSubPage3 = idSubPage3;
    this.idSubPage4 = idSubPage4;
    this.idSubPage5 = idSubPage5;
    this.idSubPage6 = idSubPage6;
    this.idSubPage7 = idSubPage7;
    this.idSubPage8 = idSubPage8;
    this.idSubPage9 = idSubPage9;
    this.idEmergencyPage = idEmergencyPage;
  }
}
