/**
 * Entity class for audio of sequence
 */
export class AudioOfSequence {
  /**
   * media's id
   */
  idMedia: Number;
  /**
   * start time
   */
  startTime: string;
  /**
   * media's name
   */
  name: string;
  /**
   * media's type
   */
  type: string;
  /**
   * media's url
   */
  url: string;
  /**
   * hour
   */
  hour: string;
  /**
   * minute
   */
  minute: string;
  /**
   * second
   */
  second: string;
  /**
   * random number
   */
  randomNumber: number;
  /**
   * true if edit duration
   */
  isEdit: boolean;
  /**
   * folderS3Name
   */
  folderS3Name: string;
  /**
   * media name encode
   */
  mediaNameEncode: string;
  /**
   * size
   */
  size: number;

  /**
   * duration
   */
  duration: number;

  /**
   * true if time invalid
   */
  isInValidTime: boolean;
}
