/**
 * class FontCharacterBitmap
 */
export class FontCharacterBitmap {
  /**
   * font name
   */
  fontName: string;
  /**
   * font size
   */
  fontSize: string;
  /**
   * url
   */
  url: string;
  /**
   * name
   */
  name: string;

  constructor(fontName: string, fontSize: string) {
    this.fontName = fontName;
    this.fontSize = fontSize;
  }
}
