import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionState } from 'app/config/action-state';
import { FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { MenuActionService } from 'app/service/menu-action.service';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'menu-device',
  templateUrl: './menu-device.component.html',
  styleUrls: ['./menu-device.component.scss']
})
export class MenuDeviceComponent implements OnInit {
  /**
   * action states for module 1
   */
  actionState1: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);

  constructor(private menuActionService: MenuActionService, private readonly store: Store<AppState>) {
    this.store
      .select(state => state)
      .subscribe(templateState => {
        this.actionState1.canUndo = templateState.templateEditorState.canUndo;
        this.actionState1.canRedo = templateState.templateEditorState.canRedo;
      });
  }

  ngOnInit(): void {}

  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;

  /**
   * execute action for Add in menu
   * @param {string} moduleName current module name
   */
  add(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for Delete in menu
   * @param {string} moduleName current module name
   */
  deleteDevice(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * change display device
   * @param {string} moduleName module name
   */
  changeDisplayDevice(moduleName: string) {
    this.menuActionService.changeDisplayDevice(moduleName);
  }

  /**
   * add new custom tag
   * @param {string} moduleName module name
   */
  addNewCustomTag(moduleName: string) {
    this.menuActionService.addNewCustomTag(moduleName);
  }

  /**
   * select custom tag
   * @param {string} moduleName module name
   */
  selectCustomTag(moduleName: string) {
    this.menuActionService.selectCustomTag(moduleName);
  }

  /**

   * edit custom tag
   * @param {string} moduleName module name
   */
  editCustomTag(moduleName: string) {
    this.menuActionService.editCustomTag(moduleName);
  }

  /**
   * toggle sort and filter item
   * @param {string} moduleName module name
   */
  toggleSortAndFilter(moduleName: string) {
    this.menuActionService.toggleSortAndFilter(moduleName);
  }

  /**
   * execute action for Undo in menu
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for Redo in menu
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }
}
