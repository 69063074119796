export class APICycle {
  id: number;
  startTime: string;
  endTime: string;
  interval: Number;

  constructor(startTime: string, endTime: string, interval: Number) {
    this.startTime = startTime;
    this.endTime = endTime;
    this.interval = interval;
  }
}
