import { AnnouncementPlaylist } from './announcement-playlist';
import { CustomTagElementAnnouncement } from './custom-tag-element-announcement';

export class CommonPlaylistRegistration {
  id: number;
  deviceId: number;
  commonAnnouncement1: number;
  commonAnnouncement2: number;
  periodicAnnouncement: number;
  deviceName: string;
  customTagElement: CustomTagElementAnnouncement;
  isSelecting: boolean;
  isChecking: boolean;
  isGroup: boolean;
  isExpand: boolean;
  playlistSpecific: Array<AnnouncementPlaylist>;
  registrationId: string;
}
