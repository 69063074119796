import { SettingType } from 'app/config/constants';

/**
 * Entity class for SettingSignageChannel
 */
export class SettingSignageChannel {
  /**
   * id
   */
  id: Number;
  /**
   * folder's id
   */
  folderId: Number;
  /**
   * media's id
   */
  mediaId: Number;

  /**
   * type of setting
   */
  type: SettingType;

  constructor(folderId?: Number, mediaId?: Number, type?: SettingType) {
    this.folderId = folderId;
    this.mediaId = mediaId;
    this.type = type;
  }
}
