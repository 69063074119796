import { IndexWord } from './index-word';
import { EventBusStop } from './event-bus-stop';
import { Label } from './label';
import { Area } from './area';
import { StationCell } from './station-cell';
import { Media } from './media';
import { Template } from './template';
import { DisplayTemplate } from './display-template';

/**
 * Entity class for bus stop
 */
export class BusStop {
  /**
   * true if bus stop is checked, and vice versa
   */
  isChecked: boolean;
  /**
   * bus stop ID
   */
  id: Number;
  /**
   * bus stop No.
   */
  no: string;
  /**
   * suffix
   */
  suffix: string;
  /**
   * bus stop name
   */
  name: string;
  /**
   * bus stop's label name
   */
  label: Label;
  /**
   * bus stop's order in route
   */
  orderNo: number;
  /**
   * list of all bus stop's index word
   */
  indexWords: Array<IndexWord>;
  /**
   * list of all bus stop's current displayed index word
   */
  displayIndexWords: Array<IndexWord>;
  /**
   * list events
   */
  events: Array<EventBusStop>;
  /**
   * id route bustop
   */
  routeBustopId: Number;
  /**
   * list area 1
   */
  areaDisplayList1: Array<Area>;
  /**
   * list area 2
   */
  areaDisplayList2: Array<Area>;
  /**
   * list cell
   */
  stationCells: Array<StationCell>;
  /**
   * media
   */
  media: Media;
  /**
   * media id
   */
  mediaId: Number;
  /**
   * list display 1
   */
  display1Templates: Array<Template>;
  /**
   * list display 2
   */
  display2Templates: Array<Template>;

  displayTemplate1: DisplayTemplate;

  displayTemplate2: DisplayTemplate;

  busStopID: string;

  /**
   * constructor
   */
  constructor(
    stopName?: string,
    stopNo?: string,
    suffix?: string,
    label?: Label,
    displayTemplate1?: DisplayTemplate,
    displayTemplate2?: DisplayTemplate
  ) {
    this.name = stopName;
    this.no = stopNo;
    this.suffix = suffix;
    this.label = label;
    this.displayTemplate1 = displayTemplate1;
    this.displayTemplate2 = displayTemplate2;
  }
}
