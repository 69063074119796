import { Area } from './area';
import { Media } from './media';
import { ObjectFitEnum, LinkDataPictureEnum, TemplateModeEnum, TypeAreaEnum } from '../../config/constants';
import { Sound } from './sound';

export class URLArea extends Area {
  /**
   *
   */
  media: Media;

  /**
   * textURL
   */
  textURL: string;
  constructor(
    name?: string,
    width?: number,
    height?: number,
    posX?: number,
    posY?: number,
    index?: number,
    isFix?: boolean,
    isURL?: boolean,
    colorTextName?: string,
    templateMode?: TemplateModeEnum
  ) {
    super(name, width, height, posX, posY, index, isFix, isURL, colorTextName, templateMode);
    this.isTimingOn = false;
  }

  /**
   * override from area class
   * check type area
   * return false if area is url area
   */
  checkTypeTextArea(): boolean {
    return false;
  }

  /**
   * override from area class
   * get url area
   */
  getArea() {
    return this;
  }
}
