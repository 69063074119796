import { ScheduleRegistrationDetail } from 'app/model/entity/schedule-registration-detail';
/**
 * class CalendarInformation
 */
export class CalendarInformation {
  /**
   * id's device
   */
  deviceId: Number;
  /**
   * name's device
   */
  deviceName: string;
  /**
   * start date
   */
  startDate: Date;
  /**
   * finish date
   */
  finishDate: Date;

  constructor(deviceId?: Number, startDate?: Date, finishDate?: Date, deviceName?: string) {
    this.deviceId = deviceId;
    this.startDate = startDate;
    this.finishDate = finishDate;
    this.deviceName = deviceName;
  }
}
