import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionState } from 'app/config/action-state';
import { FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { MenuActionService } from 'app/service/menu-action.service';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'menu-master-list',
  templateUrl: './menu-master-list.component.html',
  styleUrls: ['./menu-master-list.component.scss']
})
export class MenuMasterListComponent implements OnInit {
  /**
   * action states for module 1
   */
  actionState1: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);

  constructor(private menuActionService: MenuActionService, private readonly store: Store<AppState>) {
    this.store
      .select(state => state)
      .subscribe(templateState => {
        this.actionState1.canUndo = templateState.templateEditorState.canUndo;
        this.actionState1.canRedo = templateState.templateEditorState.canRedo;
      });
  }

  ngOnInit(): void {}

  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;

  /**
   * execute action for Add New in menu
   * @param {string} moduleName current module name
   */
  add(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for Duplicate in menu
   * @param {string} moduleName current module name
   */
  duplicate(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }

  /**
   * execute action for Delete in menu
   * @param {string} moduleName current module name
   */
  deleteBusStop(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * change label item
   * @param {string} moduleName current module name
   */
  changeLabel(moduleName: string) {
    this.menuActionService.changeLabel(moduleName);
  }

  /**
   * manage label
   * @param {string} moduleName module name
   */
  manageLabel(moduleName: string) {
    this.menuActionService.manageLabel(moduleName);
  }

  /**
   * execute action for Undo in menu
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for Redo in menu
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }
}
