import { ContentDaySchedule } from './content-day-schedule';

export class TimetableScheduleMerge {
  columnsData: string[];
  isActiveDays: boolean[];
  isEditDays: boolean[];
  contentDays: ContentDaySchedule[];
  inValidRow: boolean;
  inValidRowDulicate: boolean;
  titleTooltip: string;
  isChecked: boolean;
  constructor(columnsData: string[], isActiveDays?: boolean[]) {
    this.columnsData = columnsData;
    this.isActiveDays = isActiveDays;
  }
}
