import { ExternalContent } from './external-content';
import { City } from './city';

export class OpenWeatherContent extends ExternalContent {
  isAutoUpdate: boolean;
  cities: Array<City>;
  weatherContentDetails: any;

  constructor(contentOutputFileName?: string, cities?: Array<City>) {
    super(contentOutputFileName);
    this.cities = cities;
  }
}
