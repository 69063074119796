import { Media } from './media';
import { PictureArea } from './picture-area';
import { TextArea } from './text-area';

/**
 * class emergency data
 */
export class EmergencyData {
  /**
   * id emergency data
   */
  id: number;
  /**
   * emergency text
   */
  emergencyText: string;
  /**
   * emergency image id
   */
  emergencyImageId: number;
  /**
   * url image
   */
  urlImage: string;
  /**
   * meida of emergency
   */
  media: Media;
  /**
   * area picture emergency
   */
  areaPicture: PictureArea;
  /**
   * area text emergency
   */
  areaText: TextArea;

  constructor(textEmergency: string, idImageEmergency: number, urlImage?: string) {
    this.emergencyText = textEmergency;
    this.emergencyImageId = idImageEmergency;
    this.urlImage = urlImage;
  }
}
