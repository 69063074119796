export class ItemDetail {
  startCode: string;
  items: Array<string>;
  isInValidFormat: boolean;
  isInvalidIndexWord: boolean;
  titleTooltip: string;
  indexColumnNames: Array<number>;

  constructor(startCode?: string, items?: Array<string>) {
    this.startCode = startCode;
    this.items = items;
  }
}
