import { Area } from './area';
import { City } from './city';
import { ExternalContentDetail } from './external-content-detail';
import { IndexWordGroup } from './index-word-group';

export class OpenWeatherContentDetail extends ExternalContentDetail {
  city: City;
  forecastParam: number;
  indexWordGroup: IndexWordGroup;

  constructor(
    area?: Area,
    source?: number,
    externalContentId?: Number,
    forecastParam?: number,
    indexWordGroup?: IndexWordGroup,
    city?: City
  ) {
    super(area, source, externalContentId);
    this.city = city;
    this.forecastParam = forecastParam;
    this.indexWordGroup = indexWordGroup;
  }
}
