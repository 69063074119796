import { TextAreaLED } from './text-area-led';
import { PictureAreaLED } from './picture-area-led';

export class DataResponseArea {
  textAreas: Array<TextAreaLED>;
  pictureAreas: Array<PictureAreaLED>;
  areaIds: Array<Number>;

  constructor(textAreas: Array<TextAreaLED>, pictureAreas: Array<PictureAreaLED>, areaIds: Array<Number>) {
    this.textAreas = textAreas;
    this.pictureAreas = pictureAreas;
    this.areaIds = areaIds;
  }
}
