import { LEDTemplateModeEnum, TemplateModeEnum } from 'app/config/constants';

/**
 * Entity class sort filter object
 */
export class SortFilterObject {
  // true if data is sorted
  isSortFilter: boolean;
  // true if all option is checked
  isCheckAllOptionFilter: boolean;
  // column sort filtering
  columnSortFiltering: string;
  // true if data is filtered
  isFilter: boolean;
  // true if data not filter and sort
  isClear: boolean;
  // list current filter
  listCurrentFilter: IHash = {};
  // list sorter
  listSorted: any = [];
  // header columns
  headerColumns: any = [];
  // true if list template group display type list
  isTypeList: boolean;
  // search input value
  searchInputValue: string;
  // template group mode (filter)
  templateGroupModeFilter: TemplateModeEnum;

  templateGroupModeLEDFilter: LEDTemplateModeEnum;
  constructor() {}
}

/**
 * option filter
 */
export interface OptionFilter {
  isChecked: boolean;
  name: string;
  roleId?: Number;
  templateMode?: number;
}

/**
 * list of list options filtered
 */
export interface IHash {
  [details: string]: OptionFilter[];
}
