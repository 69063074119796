import { ExternalContent } from './external-content';
import { NewsContentDetail } from './news-content-detail';

export class NewsContent extends ExternalContent {
  pageCounts: number;
  duration: number;
  newsContentDetails: NewsContentDetail[];
  referencePositions: Array<ReferencePosition>;

  constructor(contentOutputFileName?: string, pageCounts?: number, duration?: number) {
    super(contentOutputFileName);
    this.pageCounts = pageCounts;
    this.duration = duration;
  }
}

export class ReferencePosition {
  areaId: Number;
  referenceX: number;
  referenceY: number;

  constructor(referenceX: number, referenceY: number, areaId?: Number) {
    this.areaId = areaId;
    this.referenceX = referenceX;
    this.referenceY = referenceY;
    this.areaId = areaId;
  }
}
