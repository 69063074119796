import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionState } from 'app/config/action-state';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss']
})
export class MenuUserComponent implements OnInit {
  /**
   * action states for module 1
   */
  actionState1: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);
  /**
   * false if select tab privilege setting
   */
  isShowUserList: boolean = true;
  /**
   * true if select user root
   */
  isUserRoot: boolean;
  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;
  /**
   * true if has not user exists
   */
  public hasNotUserExists: boolean;

  constructor(private menuActionService: MenuActionService, private readonly store: Store<AppState>, private dataService: DataService) {
    this.store
      .select(state => state)
      .subscribe(templateState => {
        this.actionState1.canUndo = templateState.templateEditorState.canUndo;
        this.actionState1.canRedo = templateState.templateEditorState.canRedo;
      });
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_SHOW_USER_LIST) {
        this.isShowUserList = <boolean>data[1];
      }
      if (data[0] == Constant.IS_USER_ROOT) {
        this.isUserRoot = <boolean>data[1];
      }
      if (data[0] == Constant.HAS_NOT_USER_EXISTS) {
        this.hasNotUserExists = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {}

  /**
   * execute action for Save in menu
   * @param {string} moduleName current module name
   */
  public save(moduleName: string) {
    this.menuActionService.save(moduleName);
  }

  /**
   * execute action for Add in menu
   * @param {string} moduleName current module name
   */
  public add(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  public edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for Delete in menu
   * @param {string} moduleName current module name
   */
  public deleteUser(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for sort and filter in menu
   * @param {string} moduleName current module name
   */
  public toggleSortAndFilter(moduleName: string): void {
    this.menuActionService.toggleSortAndFilter(moduleName);
  }
}
