import { Media } from './media';

export class FreeAreaMediaFile {
  pcFileName: string;
  media: Media;
  mediaName: string;
  timetableDetailId: Number;
  mediaFromPC: Media;

  constructor(pcFileName?: string, mediaName?: string, timetableDetailId?: Number, mediaFromPC?: Media) {
    this.pcFileName = pcFileName;
    this.mediaName = mediaName;
    this.timetableDetailId = timetableDetailId;
    this.mediaFromPC = mediaFromPC;
  }
}
