import { Media } from './media';

/**
 * Entity class for Video
 */
export class Video extends Media {
  /**
   * height media (dot)
   */
  height: number;
  /**
   * width media (dot)
   */
  width: number;
  /**
   * duration (s)
   */
  duration: number;
  /**
   * frame rate (frame/s)
   */
  frameRate: number;
  /**
   * bit rate (bit/s)
   */
  bitRate: number;
  /**
   * Start duration
   */
  in: number;
  /**
   * End duration
   */
  out: number;
  constructor() {
    super();
  }

  getMedia() {
    return this;
  }
}
