import { TicketDetailReservationManager } from './ticket-detail-reservation-manager';

export class ContentDayReservationManager {
  id: number;
  fullDate: Date;
  isOtherMonth: boolean;
  inactive: boolean;
  startDate: Date;
  finishDate: Date;
  color: string;
  day: number;
  ticketDetails: TicketDetailReservationManager[];
  text: string;

  constructor(day?: number, fullDate?: Date, inactive?: boolean, isOtherMonth?: boolean) {
    this.day = day;
    this.fullDate = fullDate;
    this.inactive = inactive;
    this.isOtherMonth = isOtherMonth;
  }

  public get dateInMonth(): string {
    return `${this.fullDate.getDate()}`;
  }
}
