import { ContentDay } from './content-day';
/**
 * entity class for a signage channel
 */
export class Channel {
  id: Number;
  name: string;
  contentDays: Array<ContentDay>;
  calendarDays: Array<ContentDay>;
  startDate: Date;
  projectId: Number;
  projectName: string;
  dailySchedulesName: Array<string>;
  iterator: any;

  constructor(name?: string, startDate?: Date, projectId?: Number) {
    this.name = name;
    this.startDate = startDate;
    this.calendarDays = new Array<ContentDay>();
    this.contentDays = new Array<ContentDay>();
    this.projectId = projectId;
  }
}
