import { Area } from './area';

export class Group {
  posX: number;
  posY: number;
  width: number;
  height: number;
  referencePoint: number;
  areas: Array<Area>;
  id: string;

  constructor(id: string, posX: number, posY: number, width: number, height: number, areas: Array<Area>) {
    this.id = id;
    this.posX = posX;
    this.posY = posY;
    this.width = width;
    this.height = height;
    this.referencePoint = 0;
    this.areas = areas;
  }

  /**
   * get displayed X position
   */
  public get displayPosX() {
    switch (this.referencePoint) {
      case 0:
      case 3:
      case 6:
        return this.posX;
      case 1:
      case 4:
      case 7:
        return Math.ceil(this.posX + this.width / 2);
      case 2:
      case 5:
      case 8:
        return this.posX + this.width;
      default:
        return 0;
    }
  }

  /**
   * set displayed X position
   */
  public set displayPosX(value: number) {
    switch (this.referencePoint) {
      case 0:
      case 3:
      case 6:
        this.posX = value;
        break;
      case 1:
      case 4:
      case 7:
        this.posX = Math.ceil(value - this.width / 2);
        break;
      case 2:
      case 5:
      case 8:
        this.posX = value - this.width;
        break;
      default:
        break;
    }
  }

  /**
   * get displayed Y position
   */
  public get displayPosY() {
    switch (this.referencePoint) {
      case 0:
      case 1:
      case 2:
        return this.posY;
      case 3:
      case 4:
      case 5:
        return Math.ceil(this.posY + this.height / 2);
      case 6:
      case 7:
      case 8:
        return this.posY + this.height;
      default:
        return 0;
    }
  }

  /**
   * set displayed Y position
   */
  public set displayPosY(value: number) {
    switch (this.referencePoint) {
      case 0:
      case 1:
      case 2:
        this.posY = value;
        break;
      case 3:
      case 4:
      case 5:
        this.posY = Math.ceil(value - this.height / 2);
        break;
      case 6:
      case 7:
      case 8:
        this.posY = value - this.height;
        break;
      default:
        break;
    }
  }
}
