import { Component, OnInit } from '@angular/core';
import { ActionState } from 'app/config/action-state';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-signage-display-editor',
  templateUrl: './menu-signage-display-editor.component.html',
  styleUrls: ['./menu-signage-display-editor.component.scss']
})
export class MenuSignageDisplayEditorComponent implements OnInit {
  isCheckWidthMenu: boolean;
  isCheckDisplay: boolean;
  isCheckSetting: boolean;
  isCheckPublish: boolean;
  /**
   * action states for module 1
   */
  actionState1: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);
  /**
   * true if preview on
   */
  isDisabled: boolean;
  constructor(private menuActionService: MenuActionService, private dataService: DataService, private commonService: CommonService) {
    this.isChecked = commonService.getCommonObject().isDisplay2Signage;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'isPreviewOn' || data[0] == 'isEditingStyle') {
        this.isDisabled = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {
    this.handlingDisplayMenu();
  }

  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;

  /**
   * true if choose display 2
   */
  isChecked: boolean = false;

  /**
   * execute action for save in menu
   * @param {string} moduleName current module name
   */
  save(moduleName: string) {
    this.menuActionService.save(moduleName);
  }

  /**
   * execute action for add new style in menu
   * @param {string} moduleName current module name
   */
  addNewStyle(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for edit style in menu
   * @param {string} moduleName current module name
   */
  editStyle(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for duplicate in menu
   * @param {string} moduleName current module name
   */
  duplicateStyle(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }

  /**
   * execute action for delete in menu
   * @param {string} moduleName current module name
   */
  deleteStyle(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for change Template in menu
   * @param {string} moduleName current module name
   */
  changeTemplate(moduleName: string) {
    this.menuActionService.changeTemplate(moduleName);
  }

  /**
   * execute action for Undo in menu
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for Redo in menu
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }

  /**
   * toggle sort and filter item
   * @param {string} moduleName module name
   */
  toggleSortAndFilter(moduleName: string) {
    this.menuActionService.toggleSortAndFilter(moduleName);
  }

  /**
   * stop propagation
   * @param e MouseEvent
   */
  displayTemplate(e: MouseEvent) {
    e.stopPropagation();
  }

  /**
   * execute action for Change Display in menu
   * @param {string} moduleName current module name
   */
  changeDisplay(moduleName: string) {
    this.isChecked = !this.isChecked;
    this.menuActionService.changeDisplay(moduleName);
  }

  /**
   * execute action for publish in menu
   * @param {string} moduleName current module name
   */
  publish(moduleName: string) {
    this.menuActionService.publish(moduleName);
  }

  /**
   * execute action for setting in menu
   * @param {string} moduleName current module name
   */
  setting(moduleName: string) {
    this.menuActionService.setting(moduleName);
  }

  /**
   * handle  menu  when resize screen
   */
  handlingDisplayMenu() {
    this.isCheckWidthMenu = true;
    this.isCheckDisplay = true;
    this.isCheckSetting = true;
    this.isCheckPublish = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'checkPublishSignage') {
        this.isCheckPublish = <boolean>data[1];
      }
      if (data[0] == 'checkSettingSignage') {
        this.isCheckSetting = <boolean>data[1];
      }
      if (data[0] == 'checkDisplaySignage') {
        this.isCheckDisplay = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckDisplay && this.isCheckPublish && this.isCheckSetting;
    });
  }
}
