import { BusStop } from './bus-stop';
import { Template } from './template';
import { Label } from './label';
import { StationCell } from './station-cell';

/**
 * Entity class for route
 */
export class Route {
  /**
   * ID Route
   */
  id: Number;
  /**
   * true if route is edited, and vice versa
   */
  isEdit: boolean;
  /**
   * true if route is checked, and vice versa
   */
  isChecked: boolean;
  /**
   * Label
   */
  label: Label;
  /**
   * Route No.
   */
  routeNo: string;
  /**
   * Suffix
   */
  suffix: string;
  /**
   * Route Name
   */
  name: string;
  /**
   *
   */
  display1: Template;
  /**
   *
   */
  display2: Template;
  /**
   * Project ID
   */
  projectId: Number;
  /**
   * bus stop list
   */
  busStops: Array<BusStop>;
  /**
   *
   */
  symbol: any;
  /**
   * routeID
   */
  routeID: string;
  /**
   *
   */
  displayId1: number;
  /**
   *
   */
  displayId2: number;

  constructor(routeNo?: string, routeName?: string, suffix?: string, projectId?: Number) {
    this.routeNo = routeNo;
    this.suffix = suffix;
    this.name = routeName;
    this.projectId = projectId;
    this.symbol = Symbol();
  }
}
