import { TimetableScheduleMerge } from './timetable-schedule';

export class TimetableDaily {
  id: Number;
  no: string;
  suffix: string;
  name: string;
  headers: string[];
  schedules: TimetableScheduleMerge[];
  inValidRow: boolean;
  constructor(id?: Number, no?: string, suffix?: string, name?: string, headers?: string[]) {
    this.id = id;
    this.no = no;
    this.suffix = suffix;
    this.name = name;
    this.headers = headers;
  }
}
