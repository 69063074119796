import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionState } from 'app/config/action-state';
import { FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { MenuActionService } from 'app/service/menu-action.service';
import { AppState } from 'app/store/app.state';
import { DataService } from 'app/service/data.service';

@Component({
  selector: 'menu-index-word',
  templateUrl: './menu-index-word.component.html',
  styleUrls: ['./menu-index-word.component.scss']
})
export class MenuIndexWordComponent implements OnInit {
  /**
   * action states for module 1
   */
  actionState1: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);
  isEditIndexWord: boolean;

  constructor(private menuActionService: MenuActionService, private dataService: DataService, private readonly store: Store<AppState>) {
    this.store
      .select(state => state)
      .subscribe(templateState => {
        this.actionState1.canUndo = templateState.templateEditorState.canUndo;
        this.actionState1.canRedo = templateState.templateEditorState.canRedo;
      });
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'isEditIndexWord') {
        this.isEditIndexWord = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {}

  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;

  /**
   * execute action for Add New in menu
   * @param {string} moduleName current module name
   */
  add(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for Delete in menu
   * @param {string} moduleName current module name
   */
  deleteIndexWord(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for clear field free area in menu
   * @param {string} moduleName current module name
   */
  clearField(moduleName: string) {
    this.menuActionService.clearField(moduleName);
  }
}
