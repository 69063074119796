/**
 * Entity class for media
 */
export abstract class Media {
  /**
   * ID media
   */
  id: Number;
  /**
   * Media name
   */
  name: string;
  /**
   * Media type
   */
  type: string;
  /**
   * Media url
   */
  url: string;
  /**
   * duration display
   */
  durationDisplay: string;
  /**
   * start time
   */
  startTime: string;
  /**
   * folder id
   */
  folderId: Number;
  /**
   * media owner
   */
  mediaOwner: string;
  /**
   * valid from date
   */
  validFrom: string;
  /**
   * valid to date
   */
  validTo: string;
  /**
   * description
   */
  description: string;

  /**
   * Media name encode
   */
  mediaNameEncode: string;

  randomNumber: number;

  isSelected: boolean;

  /**
   * time from
   */
  playTimeFrom: string;

  /**
   * time to
   */
  playTimeTo: string;

  abstract getMedia();
}
