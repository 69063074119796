import { ScheduleDetail } from './schedule-detail';

export class ScheduleReservation {
  /**
   * id
   */
  id: number;
  /**
   * no
   */
  no: string;
  /**
   * suffix
   */
  suffix: string;
  /**
   * name
   */
  name: string;
  /**
   * id ticket
   */
  idTicket: number;
  /**
   * is checking
   */
  isChecking: boolean;
  /**
   * schedule details
   */
  scheduleDetails: ScheduleDetail[];
  /**
   * headers
   */
  headers: string[];
  /**
   * is editing
   */
  isEditing: boolean;

  constructor(id?: number, no?: string, suffix?: string, name?: string, header?: string[]) {
    this.id = id;
    this.no = no;
    this.suffix = suffix;
    this.name = name;
    this.headers = header;
  }
}
