import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionState } from 'app/config/action-state';
import { FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'menu-bus-information',
  templateUrl: './menu-bus-information.component.html',
  styleUrls: ['./menu-bus-information.component.scss']
})
export class MenuBusInformationComponent implements OnInit {
  isCheckWidthMenu: boolean;
  isCheckDisplay: boolean;
  isCheckPublish: boolean;
  /**
   * action states for module 1
   */
  actionState1: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);

  constructor(
    private menuActionService: MenuActionService,
    private readonly store: Store<AppState>,
    private dataService: DataService,
    private commonService: CommonService
  ) {
    this.isChecked = commonService.getCommonObject().isDisPlay2OnBus;
    this.store
      .select(state => state)
      .subscribe(templateState => {
        this.actionState1.canUndo = templateState.templateEditorState.canUndo;
        this.actionState1.canRedo = templateState.templateEditorState.canRedo;
      });
  }

  ngOnInit(): void {
    this.handlingDisplayMenu();
  }

  /**
   * FIELD_COMPONENT
   */
  FIELD_COMPONENT = FIELD_COMPONENT;

  /**
   * MODULE_NAME
   */
  MODULE_NAME = MODULE_NAME;

  /**
   * true if choose display 2
   */
  isChecked: boolean = false;

  /**
   * execute action for Save in menu
   * @param {string} moduleName current module name
   */
  save(moduleName: string) {
    this.menuActionService.save(moduleName);
  }

  /**
   * execute action for Delete in menu
   * @param {string} moduleName current module name
   */
  deleteEvent(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for Change Template in menu
   * @param {string} moduleName current module name
   */
  changeTemplate(moduleName: string) {
    this.menuActionService.changeTemplate(moduleName);
  }

  /**
   * execute action for Undo in menu
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for Redo in menu
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }

  /**
   * stop propagation
   * @param e MouseEvent
   */
  displayTemplate(e: MouseEvent) {
    e.stopPropagation();
  }

  /**
   * execute action for Change Display in menu
   * @param {string} moduleName current module name
   */
  changeDisplay(moduleName: string) {
    this.isChecked = !this.isChecked;
    this.menuActionService.changeDisplay(moduleName);
  }

  /**
   * execute action for Change Template in menu
   * @param {string} moduleName current module name
   */
  publish(moduleName: string) {
    this.menuActionService.publish(moduleName);
  }

  /**
   * handle  menu  when resize screen
   */
  handlingDisplayMenu() {
    this.isCheckWidthMenu = true;
    this.isCheckDisplay = true;
    this.isCheckPublish = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'checkPublishOnBus') {
        this.isCheckPublish = <boolean>data[1];
      }
      if (data[0] == 'checkDisplayOnBus') {
        this.isCheckDisplay = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckDisplay && this.isCheckPublish;
    });
  }
}
