import { ExternalContent } from './external-content';

export class WeatherContent extends ExternalContent {
  municipalCode: string;
  municipalName: string;
  weatherContentDetails: any;

  constructor(contentOutputFileName?: string, municipalCode?: string) {
    super(contentOutputFileName);
    this.municipalCode = municipalCode;
  }
}
