import { Component, OnInit } from '@angular/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-spot',
  templateUrl: './menu-spot.component.html',
  styleUrls: ['./menu-spot.component.scss']
})
export class MenuSpotComponent implements OnInit {
  FIELD_COMPONENT = FIELD_COMPONENT;
  MODULE_NAME = MODULE_NAME;
  isCallApiTabSpot: boolean; // is editing application
  isEditingTabSpot: boolean; // is editing crew account
  constructor(private dataService: DataService, private menuActionService: MenuActionService) {
    this.dataService.currentData.subscribe(data => {
      switch (data[0]) {
        case Constant.IS_CALL_API_TAB_SPOT: {
          this.isCallApiTabSpot = <boolean>data[1];
          break;
        }
        case Constant.IS_EDITING_SPOT: {
          this.isEditingTabSpot = <boolean>data[1];
          break;
        }
      }
    });
  }

  ngOnInit(): void {}

  /**
   * execute action for Add in menu
   * @param {string} moduleName current module name
   */
  add(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for Edit in menu
   * @param {string} moduleName current module name
   */
  edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for Delete in menu
   * @param {string} moduleName current module name
   */
  delete(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for Duplicate in menu
   * @param {string} moduleName current module name
   */
  duplicate(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }
}
