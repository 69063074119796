/**
 * Entity class for media of sequence
 */
export class MediaOfSequence {
  /**
   * media's id
   */
  idMedia: Number;
  /**
   * start time
   */
  startTime: string;
  /**
   * media's name
   */
  name: string;
  /**
   * media's type
   */
  type: string;
  /**
   * media's url
   */
  url: string;
  /**
   * width
   */
  width: number;
  /**
   * height
   */
  height: number;
  /**
   * duration
   */
  duration: number;
  /**
   * valid from
   */
  validFrom: string;
  /**
   * valid to
   */
  validTo: string;
  /**
   * hour
   */
  hour: string;
  /**
   * minute
   */
  minute: string;
  /**
   * second
   */
  second: string;
  /**
   * random number
   */
  randomNumber: number;
  /**
   * true if edit duration
   */
  isEdit: boolean;
  /**
   * true if time invalid
   */
  isInValidTime: boolean;
  /**
   * folderS3Name
   */
  folderS3Name: string;
  /**
   * media name encode
   */
  mediaNameEncode: string;
  /**
   * size
   */
  size: number;
  /**
   * time from
   */
  playTimeFrom: string;
  /**
   * time to
   */
  playTimeTo: string;
}
