import { Area } from './area';
import { Media } from './media';

export class TimetableDetailURLArea {
  /**
   * Id
   */
  id: Number;

  /**
   * Area display 1 id
   */
  areaDisplay1Id: Number;

  /**
   * Area display 2 id
   */
  areaDisplay2Id: Number;

  /**
   * Text
   */
  textUrl: string;

  /**
   * Timetable Id
   */
  timetableId: Number;

  /**
   * Area display 1
   */
  areaDisplay1: Area;

  /**
   * Area display 2
   */
  areaDisplay2: Area;

  /**
   * Media
   */
  media: Media;

  /**
   * Has text
   */
  hasText: boolean;

  /**
   * symbol
   */
  symbol: symbol;

  constructor(timetableId?: Number, areaDisplay1Id?: Number, areaDisplay1?: Area) {
    this.timetableId = timetableId;
    this.areaDisplay1Id = areaDisplay1Id;
    this.areaDisplay1 = areaDisplay1;
    if (!this.symbol) {
      this.symbol = Symbol();
    }
  }
}
