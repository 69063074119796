import { ConditionMedia } from './condition-media';

export class ConditionFilterMediaLog {
  id: Number;
  userId: Number;
  registrationIds: Array<string>;
  condition: ConditionMedia;
  period: number;
  dateFrom: string;
  dateTo: string;
  playlistNames: Array<string>;

  constructor(userId?: Number, period?: number) {
    this.userId = userId;
    this.period = period;
  }
}
