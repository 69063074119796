import { Media } from './media';

/**
 * Entity class for folder media
 */
export class FolderMedia {
  /**
   * ID folder
   */
  id: Number;
  /**
   * project name
   */
  name: string;
  /**
   * folder name
   */
  folderS3Name: string;
  /**
   * parent id
   */
  parentId: Number;
  /**
   * Constructor
   * @param id folder ID
   * @param name folder Name
   */
  constructor(name?: string, folderS3Name?: string, id?: Number) {
    this.name = name;
    this.folderS3Name = folderS3Name;
    this.id = id;
  }
}
