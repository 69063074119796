import { SequenceNotificationDetail } from './sequence-notification-detail';

/**
 * Entity class for sequence
 */
export class SequenceNotification {
  id: number;
  name: string;
  group: number;
  isEdit: boolean;
  sequence: SequenceNotificationDetail[];
  timelineDivision: number;

  constructor(
    id?: number,
    name?: string,
    group?: number,
    isEdit?: boolean,
    sequence?: SequenceNotificationDetail[],
    timelineDivision?: number
  ) {
    this.id = id;
    this.name = name;
    this.group = group;
    this.isEdit = isEdit;
    this.sequence = sequence;
    this.timelineDivision = timelineDivision;
  }

  getMedia() {
    return this;
  }
}
