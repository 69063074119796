export class TextHighlightingSetting {
  id: Number;
  titleName: string;
  name: string;
  blinking: boolean;
  object: string;
  color: string;
  isActive: boolean;
  readOnlyName: boolean;
  opacity: Number;
}
