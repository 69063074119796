/**
 * Entity class for index word
 */
export class Translation {
  /**
   * base Language Code
   */
  baseLanguageCode: string;
  /**
   * text translate
   */
  text: string;
  /**
   * source Language Code
   */
  sourceLanguageCode: string;
  /**
   * target Language Code
   */
  targetLanguageCode: [];
}
