import { Media } from './media';
import { Constant } from '../../config/constants';

/**
 * entity class for a signage playlist
 */
export class DailySchedule {
  id: Number;
  name: string;
  color: string;
  mediaList: Array<Program>;
  projectId: Number;

  constructor(name?: string, color?: string, projectId?: Number) {
    this.name = name;
    this.color = color;
    this.projectId = projectId;
    this.mediaList = new Array<Program>();
  }
}

export class Program {
  id: Number;
  media: Media;
  startTime: number;
  duration: number;
  symbol: Symbol = Symbol();

  get startPixel(): number {
    return (this.startTime / Constant.PLAY_SEGMENT) * Constant.SEGMENT_HEIGHT;
  }

  get durationPixel(): number {
    // return this.duration / 120 * Constant.SEGMENT_HEIGHT;
    return (this.duration / Constant.PLAY_SEGMENT) * Constant.SEGMENT_HEIGHT;
  }

  set startPixel(value: number) {
    switch (Constant.PLAY_SEGMENT) {
      case 120:
        this.startTime = Math.round(((value / Constant.SEGMENT_HEIGHT) * Constant.PLAY_SEGMENT) / 1) * 1;
        break;
      case 30:
        this.startTime = Math.round(((value / Constant.SEGMENT_HEIGHT) * Constant.PLAY_SEGMENT) / 1) * 1;
        break;
      case 15:
        this.startTime = Math.round(((value / Constant.SEGMENT_HEIGHT) * Constant.PLAY_SEGMENT) / 1) * 1;
        break;
      case 5:
        this.startTime = Math.round(((value / Constant.SEGMENT_HEIGHT) * Constant.PLAY_SEGMENT) / 1) * 1;
        break;
      case 2:
        this.startTime = Math.round(((value / Constant.SEGMENT_HEIGHT) * Constant.PLAY_SEGMENT) / 1) * 1;
        break;
      case 1:
        this.startTime = Math.round(((value / Constant.SEGMENT_HEIGHT) * Constant.PLAY_SEGMENT) / 1) * 1;
        break;
      default:
        break;
    }
  }

  set durationPixel(value: number) {
    switch (Constant.PLAY_SEGMENT) {
      case 120:
        this.duration = Math.round(((value / Constant.SEGMENT_HEIGHT) * Constant.PLAY_SEGMENT) / 1) * 1;
        break;
      case 30:
        this.duration = Math.round(((value / Constant.SEGMENT_HEIGHT) * Constant.PLAY_SEGMENT) / 1) * 1;
        break;
      case 15:
        this.duration = Math.round(((value / Constant.SEGMENT_HEIGHT) * Constant.PLAY_SEGMENT) / 1) * 1;
        break;
      case 5:
        this.duration = Math.round(((value / Constant.SEGMENT_HEIGHT) * Constant.PLAY_SEGMENT) / 1) * 1;
        break;
      case 2:
        this.duration = Math.round(((value / Constant.SEGMENT_HEIGHT) * Constant.PLAY_SEGMENT) / 1) * 1;
        break;
      case 1:
        this.duration = Math.round(((value / Constant.SEGMENT_HEIGHT) * Constant.PLAY_SEGMENT) / 1) * 1;
        break;
      default:
        break;
    }
  }

  constructor(media?: Media, startTime?: number, duration?: number, id?: Number) {
    this.media = media;
    this.startTime = startTime;
    this.duration = duration;
    this.id = id;
  }
}
