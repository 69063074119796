/**
 * entity class ContentDaySchedule
 */
export class ContentDaySchedule {
  id: Number;
  fullDate: Date;
  isOtherMonth: boolean;
  inactive: boolean;
  day: number;
  isActive: boolean;
  isEdit: boolean;

  constructor(day?: number, fullDate?: Date) {
    this.day = day;
    this.fullDate = fullDate;
  }

  public get dateInMonth(): string {
    return `${this.fullDate.getDate()}`;
  }
}
