export class NoticeText {
  id: Number;
  label: string;
  text: string;
  delta: string;
  parsedDelta: string;
  folderId: number;

  constructor(id?: Number, label?: string, text?: string, delta?: string, parsedDelta?: string, folderId?: number) {
    this.id = id;
    this.label = label ?? '';
    this.text = text ?? '';
    this.delta = delta ?? '';
    this.parsedDelta = parsedDelta ?? '';
    this.folderId = folderId;
  }
}
