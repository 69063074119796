/**
 * Entity class for SettingDeviceAutoRefresh
 */
export class SettingDeviceAutoRefresh {
  /**
   * id
   */
  id: Number;
  /**
   * isAutoRefresh
   */
  autoRefresh: boolean;
  /**
   * time
   */
  timeAutoRefresh: number;

  constructor(autoRefresh: boolean, timeAutoRefresh: number) {
    this.autoRefresh = autoRefresh;
    this.timeAutoRefresh = timeAutoRefresh;
  }
}
