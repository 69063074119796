import { Media } from './media';
import { Area } from './area';
import { OutputOptionEnum } from '../../config/constants';

export class EventBusStop {
  /**
   * id
   */
  id: Number;
  /**
   * routeId
   */
  routeId: Number;
  /**
  * busStopId
  */
  busStopId: Number;
  /**
  * templateId
  */
  templateId: Number;
  /**
  * template id 2
  */
  templateId2: Number;
  /**
   * area display 1
   */
  areaDisplay1: Area;
  /**
   * area display 2
   */
  areaDisplay2: Area;
  /**
   * medias
   */
  medias: Array<Media>;
  /**
   * duration
   */
  duration: number;
  /**
   * text
   */
  text: string;
  /**
   * outputOption
   */
  outputOption: OutputOptionEnum;
  /**
   *
   */
  isText: boolean;

  symbol: any

  randomNumber: number;

  orderNoBusStop: number;

  constructor(routeId?: Number, busStopId?: Number, templateId?: Number, templateId2?: Number, orderNoBusStop?: number) {
    this.templateId = templateId;
    this.templateId2 = templateId2;
    this.routeId = routeId;
    this.busStopId = busStopId;
    this.isText = false;
    this.medias = new Array<Media>();
    this.symbol = Symbol();
    this.orderNoBusStop = orderNoBusStop;
  }
}
