import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionState } from 'app/config/action-state';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'menu-led-layout-editor',
  templateUrl: './menu-led-layout-editor.component.html',
  styleUrls: ['./menu-led-layout-editor.component.scss']
})
export class MenuLedLayoutEditorComponent implements OnInit {
  /**
   * action states for module 1
   */
  actionState: ActionState = new ActionState('', true, true, true, true, true, true, true, false, false);
  FIELD_COMPONENT = FIELD_COMPONENT;
  MODULE_NAME = MODULE_NAME;
  isSelectedTemplateGroup: boolean;
  isShowCanvas: boolean = false;

  constructor(private menuActionService: MenuActionService, private readonly store: Store<AppState>, private dataService: DataService) {
    this.store
      .select(state => state)
      .subscribe(templateState => {
        this.actionState.canUndo = templateState.templateEditorState.canUndo;
        this.actionState.canRedo = templateState.templateEditorState.canRedo;
      });
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_SELECTED_TEMPLATE_GROUP) {
        this.isSelectedTemplateGroup = <boolean>data[1];
      }
      if (data[0] == Constant.IS_SHOW_CANVAS_LED) {
        this.isShowCanvas = <boolean>data[1];
      }
    });
  }

  ngOnInit(): void {}

  /**
   * execute action for save in menu
   * @param {string} moduleName current module name
   */
  save(moduleName: string) {
    this.menuActionService.save(moduleName);
  }

  /**
   * execute action for save as in menu
   * @param moduleName current module name
   */
  saveAs(moduleName: string) {
    this.menuActionService.saveAs(moduleName);
  }

  /**
   * execute action for add new folder in menu
   * @param {string} moduleName current module name
   */
  addFolder(moduleName: string) {
    this.menuActionService.addFolder(moduleName);
  }

  /**
   * execute action for add in menu
   * @param {string} moduleName current module name
   */
  add(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * execute action for exit in menu
   * @param {string} moduleName current module name
   */
  exit(moduleName: string) {
    this.menuActionService.exit(moduleName);
  }

  /**
   * execute action for edit in menu
   * @param {string} moduleName current module name
   */
  edit(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * execute action for duplicate in menu
   * @param {string} moduleName current module name
   */
  duplicate(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }

  /**
   * execute action for delete in menu
   * @param {string} moduleName current module name
   */
  delete(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * execute action for undo in menu
   * @param {string} moduleName current module name
   */
  undo(moduleName: string) {
    this.menuActionService.undo(moduleName);
  }

  /**
   * execute action for redo in menu
   * @param {string} moduleName current module name
   */
  redo(moduleName: string) {
    this.menuActionService.redo(moduleName);
  }
}
