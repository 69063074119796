import { Media } from './media';

export class StationContentMediaFile {
  pcFileName: string;
  stopID: string;
  routeID: string;
  busStopId: Number;
  routeId: Number;
  media: Media;

  constructor(pcFileName?: string, stopID?: string, routeID?: string, busStopId?: Number, routeId?: Number, media?: Media) {
    this.pcFileName = pcFileName;
    this.stopID = stopID;
    this.routeID = routeID;
    this.busStopId = busStopId;
    this.routeId = routeId;
    this.media = media;
  }
}
