import { Media } from './media';

/**
 * Entity class for Image
 */
export class Image extends Media {
  /**
   * height media (dot)
   */
  height: number;
  /**
   * width media (dot)
   */
  width: number;

  /**
   * duration (s)
   */
  duration: number;

  constructor(width?: number, height?: number) {
    super();
    this.width = width;
    this.height = height;
  }
  getMedia() {
    return this;
  }
}
