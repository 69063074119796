export class AppDesignDTO {
  id: Number;

  appDesignId: string;

  appDesignName: string;

  appTopImage: string;

  appTopImagePath: string;

  mainColor: string;

  subColor: string;

  backgroundColor: string;

  textColor: string;

  borderColor: string;

  inactiveColor: string;

  applied: boolean;

  isEdit?: boolean;

  constructor(
    id?: Number,
    appDesignId?: string,
    appDesignName?: string,
    appTopImage?: string,
    appTopImagePath?: string,
    mainColor: string = '#50B4F0',
    subColor: string = '#50B4F0',
    backgroundColor: string = '#F0F0F0',
    textColor: string = '#000000',
    borderColor: string = '#B4B4B4',
    inactiveColor: string = '#B4B4B4',
    applied?: boolean
  ) {
    this.appDesignId = appDesignId;
    this.appDesignName = appDesignName;
    this.appTopImage = appTopImage;
    this.appTopImagePath = appTopImagePath;
    this.mainColor = mainColor;
    this.subColor = subColor;
    this.backgroundColor = backgroundColor;
    this.textColor = textColor;
    this.borderColor = borderColor;
    this.inactiveColor = inactiveColor;
    this.applied = applied;
  }
}
