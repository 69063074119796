/**
 * entity class for a common table
 */
export class CommonTable {
  id: number;
  key: string;
  value: string;

  constructor(key?: string, value?: string) {
    this.key = key;
    this.value = value;
  }
}
